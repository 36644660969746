"use client";

// import React from "react";
// import {
// 	CheckCircle,
// 	ChevronDown,
// 	ChevronUp,
// 	Menu,
// 	Star,
// 	X,
// } from "lucide-react";

// const menuItems = [
// 	{
// 		name: "Home",
// 		href: "#",
// 	},
// 	{
// 		name: "About",
// 		href: "#",
// 	},
// 	{
// 		name: "Contact",
// 		href: "#",
// 	},
// 	{
// 		name: "Blogs",
// 		href: "#",
// 	},
// ];

// export function LandinngPage() {
// 	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

// 	const toggleMenu = () => {
// 		setIsMenuOpen(!isMenuOpen);
// 	};

// 	return (
// 		<div className="w-full">
// 			<header className="relative w-full border-b bg-white pb-4">
// 				<div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-2">
// 					<div className="inline-flex items-center space-x-2">
// 						<span>
// 							<svg
// 								width="30"
// 								height="30"
// 								viewBox="0 0 50 56"
// 								fill="none"
// 								xmlns="http://www.w3.org/2000/svg"
// 							>
// 								<path
// 									d="M23.2732 0.2528C20.8078 1.18964 2.12023 12.2346 1.08477 13.3686C0 14.552 0 14.7493 0 27.7665C0 39.6496 0.0986153 41.1289 0.83823 42.0164C2.12023 43.5449 23.2239 55.4774 24.6538 55.5267C25.9358 55.576 46.1027 44.3832 48.2229 42.4602C49.3077 41.474 49.3077 41.3261 49.3077 27.8158C49.3077 14.3055 49.3077 14.1576 48.2229 13.1714C46.6451 11.7415 27.1192 0.450027 25.64 0.104874C24.9497 -0.0923538 23.9142 0.00625992 23.2732 0.2528ZM20.2161 21.8989C20.2161 22.4906 18.9835 23.8219 17.0111 25.3997C15.2361 26.7803 13.8061 27.9637 13.8061 28.0623C13.8061 28.1116 15.2361 29.0978 16.9618 30.2319C18.6876 31.3659 20.2655 32.6479 20.4134 33.0917C20.8078 34.0286 19.871 35.2119 18.8355 35.2119C17.8001 35.2119 9.0233 29.3936 8.67815 28.5061C8.333 27.6186 9.36846 26.5338 14.3485 22.885C17.6521 20.4196 18.4904 20.0252 19.2793 20.4196C19.7724 20.7155 20.2161 21.3565 20.2161 21.8989ZM25.6893 27.6679C23.4211 34.9161 23.0267 35.7543 22.1391 34.8668C21.7447 34.4723 22.1391 32.6479 23.6677 27.9637C26.2317 20.321 26.5275 19.6307 27.2671 20.3703C27.6123 20.7155 27.1685 22.7864 25.6893 27.6679ZM36.0932 23.2302C40.6788 26.2379 41.3198 27.0269 40.3337 28.1609C39.1503 29.5909 31.6555 35.2119 30.9159 35.2119C29.9298 35.2119 28.9436 33.8806 29.2394 33.0424C29.3874 32.6479 30.9652 31.218 32.7403 29.8867L35.9946 27.4706L32.5431 25.1532C30.6201 23.9205 29.0915 22.7371 29.0915 22.5892C29.0915 21.7509 30.2256 20.4196 30.9159 20.4196C31.3597 20.4196 33.6771 21.7016 36.0932 23.2302Z"
// 									fill="black"
// 								/>
// 							</svg>
// 						</span>
// 						<span className="font-bold">DevUI</span>
// 					</div>
// 					<div className="hidden lg:block">
// 						<ul className="inline-flex space-x-8">
// 							{menuItems.map((item) => (
// 								<li key={item.name}>
// 									<a
// 										href={item.href}
// 										className="text-sm font-semibold text-gray-800 hover:text-gray-900"
// 									>
// 										{item.name}
// 									</a>
// 								</li>
// 							))}
// 						</ul>
// 					</div>
// 					<div className="hidden lg:block">
// 						<button
// 							type="button"
// 							className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 						>
// 							Button text
// 						</button>
// 					</div>
// 					<div className="lg:hidden">
// 						<Menu onClick={toggleMenu} className="h-6 w-6 cursor-pointer" />
// 					</div>
// 					{isMenuOpen && (
// 						<div className="absolute inset-x-0 top-0 z-50 origin-top-right transform p-2 transition lg:hidden">
// 							<div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
// 								<div className="px-5 pb-6 pt-5">
// 									<div className="flex items-center justify-between">
// 										<div className="inline-flex items-center space-x-2">
// 											<span>
// 												<svg
// 													width="30"
// 													height="30"
// 													viewBox="0 0 50 56"
// 													fill="none"
// 													xmlns="http://www.w3.org/2000/svg"
// 												>
// 													<path
// 														d="M23.2732 0.2528C20.8078 1.18964 2.12023 12.2346 1.08477 13.3686C0 14.552 0 14.7493 0 27.7665C0 39.6496 0.0986153 41.1289 0.83823 42.0164C2.12023 43.5449 23.2239 55.4774 24.6538 55.5267C25.9358 55.576 46.1027 44.3832 48.2229 42.4602C49.3077 41.474 49.3077 41.3261 49.3077 27.8158C49.3077 14.3055 49.3077 14.1576 48.2229 13.1714C46.6451 11.7415 27.1192 0.450027 25.64 0.104874C24.9497 -0.0923538 23.9142 0.00625992 23.2732 0.2528ZM20.2161 21.8989C20.2161 22.4906 18.9835 23.8219 17.0111 25.3997C15.2361 26.7803 13.8061 27.9637 13.8061 28.0623C13.8061 28.1116 15.2361 29.0978 16.9618 30.2319C18.6876 31.3659 20.2655 32.6479 20.4134 33.0917C20.8078 34.0286 19.871 35.2119 18.8355 35.2119C17.8001 35.2119 9.0233 29.3936 8.67815 28.5061C8.333 27.6186 9.36846 26.5338 14.3485 22.885C17.6521 20.4196 18.4904 20.0252 19.2793 20.4196C19.7724 20.7155 20.2161 21.3565 20.2161 21.8989ZM25.6893 27.6679C23.4211 34.9161 23.0267 35.7543 22.1391 34.8668C21.7447 34.4723 22.1391 32.6479 23.6677 27.9637C26.2317 20.321 26.5275 19.6307 27.2671 20.3703C27.6123 20.7155 27.1685 22.7864 25.6893 27.6679ZM36.0932 23.2302C40.6788 26.2379 41.3198 27.0269 40.3337 28.1609C39.1503 29.5909 31.6555 35.2119 30.9159 35.2119C29.9298 35.2119 28.9436 33.8806 29.2394 33.0424C29.3874 32.6479 30.9652 31.218 32.7403 29.8867L35.9946 27.4706L32.5431 25.1532C30.6201 23.9205 29.0915 22.7371 29.0915 22.5892C29.0915 21.7509 30.2256 20.4196 30.9159 20.4196C31.3597 20.4196 33.6771 21.7016 36.0932 23.2302Z"
// 														fill="black"
// 													/>
// 												</svg>
// 											</span>
// 											<span className="font-bold">DevUI</span>
// 										</div>
// 										<div className="-mr-2">
// 											<button
// 												type="button"
// 												onClick={toggleMenu}
// 												className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 											>
// 												<span className="sr-only">Close menu</span>
// 												<X className="h-6 w-6" aria-hidden="true" />
// 											</button>
// 										</div>
// 									</div>
// 									<div className="mt-6">
// 										<nav className="grid gap-y-4">
// 											{menuItems.map((item) => (
// 												<a
// 													key={item.name}
// 													href={item.href}
// 													className="-m-3 flex items-center rounded-md p-3 text-sm font-semibold hover:bg-gray-50"
// 												>
// 													<span className="ml-3 text-base font-medium text-gray-900">
// 														{item.name}
// 													</span>
// 												</a>
// 											))}
// 										</nav>
// 									</div>
// 									<button
// 										type="button"
// 										className="mt-4 w-full rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 									>
// 										Button text
// 									</button>
// 								</div>
// 							</div>
// 						</div>
// 					)}
// 				</div>
// 			</header>
// 			{/* Hero Section */}
// 			<div className="relative w-full bg-white">
// 				<div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
// 					<div className="flex flex-col justify-center px-4 py-12 md:py-16 lg:col-span-7 lg:gap-x-6 lg:px-6 lg:py-24 xl:col-span-6">
// 						<div className="mt-8 flex max-w-max items-center space-x-2 rounded-full bg-gray-100 p-1">
// 							<div className="rounded-full bg-white p-1 px-2">
// 								<p className="text-sm font-medium">We&apos; hiring</p>
// 							</div>
// 							<p className="text-sm font-medium">Join our team &rarr;</p>
// 						</div>
// 						<h1 className="mt-8 text-3xl font-bold tracking-tight text-black md:text-4xl lg:text-6xl">
// 							People who care about your growth
// 						</h1>
// 						<p className="mt-8 text-lg text-gray-700">
// 							Lorem ipsum dolor sit amet consectetur adipisicing elit.
// 							Consequatur modi blanditiis dolores quasi eaque explicabo!
// 						</p>
// 						<form action="" className="mt-8 flex items-start space-x-2">
// 							<div>
// 								<input
// 									className="flex w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
// 									type="email"
// 									placeholder="Enter your email"
// 									id="email"
// 								></input>
// 								<p className="mt-2 text-sm text-gray-500">
// 									We care about your privacy
// 								</p>
// 							</div>
// 							<div>
// 								<button
// 									type="button"
// 									className="rounded-md bg-black px-3 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 								>
// 									Subscribe
// 								</button>
// 							</div>
// 						</form>
// 					</div>
// 					<div className="relative lg:col-span-5 lg:-mr-8 xl:col-span-6">
// 						<img
// 							className="aspect-[3/2] bg-gray-50 object-cover lg:aspect-[4/3] lg:h-[700px] xl:aspect-[16/9]"
// 							src="https://plus.unsplash.com/premium_photo-1679079456783-5d862f755557?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjQ3fHxtYW4lMjB3aXRoJTIwbGFwdG9wfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60"
// 							alt=""
// 						/>
// 					</div>
// 				</div>
// 			</div>
// 			{/* Features Section */}
// 			<div className="mx-auto my-32 max-w-7xl px-2 lg:px-8">
// 				<div className="grid grid-cols-1 gap-y-8 text-center sm:grid-cols-2 sm:gap-12 lg:grid-cols-4">
// 					<div>
// 						<div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-blue-100">
// 							<svg
// 								className="h-9 w-9 text-blue-600"
// 								xmlns="http://www.w3.org/2000/svg"
// 								fill="none"
// 								viewBox="0 0 24 24"
// 								stroke="currentColor"
// 							>
// 								<path
// 									strokeLinecap="round"
// 									strokeLinejoin="round"
// 									strokeWidth="1.5"
// 									d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"
// 								/>
// 							</svg>
// 						</div>
// 						<h3 className="mt-8 text-lg font-semibold text-black">
// 							Secured Payments
// 						</h3>
// 						<p className="mt-4 text-sm text-gray-600">
// 							Amet minim mollit non deserunt ullamco est sit aliqua dolor do
// 							amet sint. Velit officia consequat duis enim velit mollit.
// 						</p>
// 					</div>
// 					<div>
// 						<div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-orange-100">
// 							<svg
// 								className="h-9 w-9 text-orange-600"
// 								xmlns="http://www.w3.org/2000/svg"
// 								fill="none"
// 								viewBox="0 0 24 24"
// 								stroke="currentColor"
// 							>
// 								<path
// 									strokeLinecap="round"
// 									strokeLinejoin="round"
// 									strokeWidth="1.5"
// 									d="M13 10V3L4 14h7v7l9-11h-7z"
// 								/>
// 							</svg>
// 						</div>
// 						<h3 className="mt-8 text-lg font-semibold text-black">
// 							Fast & Easy to Load
// 						</h3>
// 						<p className="mt-4 text-sm text-gray-600">
// 							Amet minim mollit non deserunt ullamco est sit aliqua dolor do
// 							amet sint. Velit officia consequat duis enim velit mollit.
// 						</p>
// 					</div>
// 					<div>
// 						<div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-green-100">
// 							<svg
// 								className="h-9 w-9 text-green-600"
// 								xmlns="http://www.w3.org/2000/svg"
// 								fill="none"
// 								viewBox="0 0 24 24"
// 								stroke="currentColor"
// 							>
// 								<path
// 									strokeLinecap="round"
// 									strokeLinejoin="round"
// 									strokeWidth="1.5"
// 									d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
// 								/>
// 							</svg>
// 						</div>
// 						<h3 className="mt-8 text-lg font-semibold text-black">
// 							Light & Dark Version
// 						</h3>
// 						<p className="mt-4 text-sm text-gray-600">
// 							Amet minim mollit non deserunt ullamco est sit aliqua dolor do
// 							amet sint. Velit officia consequat duis enim velit mollit.
// 						</p>
// 					</div>
// 					<div>
// 						<div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-red-100">
// 							<svg
// 								className="h-9 w-9 text-red-600"
// 								xmlns="http://www.w3.org/2000/svg"
// 								fill="none"
// 								viewBox="0 0 24 24"
// 								stroke="currentColor"
// 							>
// 								<path
// 									strokeLinecap="round"
// 									strokeLinejoin="round"
// 									strokeWidth="1.5"
// 									d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
// 								/>
// 							</svg>
// 						</div>
// 						<h3 className="mt-8 text-lg font-semibold text-black">
// 							Filter Blocks
// 						</h3>
// 						<p className="mt-4 text-sm text-gray-600">
// 							Amet minim mollit non deserunt ullamco est sit aliqua dolor do
// 							amet sint. Velit officia consequat duis enim velit mollit.
// 						</p>
// 					</div>
// 				</div>
// 			</div>
// 			{/* FAQs */}
// 			<section className="mx-auto max-w-7xl bg-gray-50 px-2 py-10 md:px-0">
// 				<div>
// 					<div className="mx-auto max-w-2xl lg:text-center">
// 						<h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
// 							Frequently Asked Questions
// 						</h2>
// 						<p className="mt-4 max-w-xl text-base leading-relaxed text-gray-600 lg:mx-auto">
// 							Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facere,
// 							assumenda
// 						</p>
// 					</div>
// 					<div className="mx-auto mt-8 max-w-3xl space-y-4 md:mt-16">
// 						<div className="cursor-pointer rounded-md border border-gray-400 shadow-lg transition-all duration-200">
// 							<button
// 								type="button"
// 								className="flex w-full items-center justify-between px-4 py-5 sm:p-6"
// 							>
// 								<span className="flex text-lg font-semibold text-black">
// 									How do I get started?
// 								</span>

// 								<ChevronUp className="h-5 w-5 text-gray-500" />
// 							</button>
// 							<div className="px-4 pb-5 sm:px-6 sm:pb-6">
// 								<p className="text-gray-500">
// 									Lorem ipsum dolor sit amet consectetur adipisicing elit.
// 									Repellat aliquam adipisci iusto aperiam? Sint asperiores sequi
// 									nobis inventore ratione deleniti?
// 								</p>
// 							</div>
// 						</div>
// 						{Array.from({ length: 2 }).map((_, i) => (
// 							<div
// 								key={i}
// 								className="cursor-pointer rounded-md border border-gray-400 transition-all duration-200"
// 							>
// 								<button
// 									type="button"
// 									className="flex w-full items-start justify-between px-4 py-5 sm:p-6 md:items-center"
// 								>
// 									<span className="flex text-start text-lg font-semibold text-black">
// 										What is the difference between a free and paid account?
// 									</span>
// 									<ChevronDown className="hidden h-5 w-5 text-gray-500 md:block" />
// 								</button>
// 							</div>
// 						))}
// 					</div>
// 					<p className="textbase mt-6 text-center text-gray-600">
// 						Can&apos;t find what you&apos;re looking for?{" "}
// 						<a
// 							href="#"
// 							title=""
// 							className="font-semibold text-black hover:underline"
// 						>
// 							Contact our support
// 						</a>
// 					</p>
// 				</div>
// 			</section>
// 			{/* Pricing Section */}
// 			<div className="mx-auto my-12 max-w-7xl md:my-24 lg:my-32">
// 				<div className="lg:grid lg:grid-cols-12 lg:gap-x-6">
// 					<div className="px-4 py-10 lg:col-span-5 lg:px-0">
// 						<span className="mb-8 inline-block rounded-full border p-1 px-3 text-xs font-semibold">
// 							Pricing that fits your budget
// 						</span>
// 						<h1 className="text-3xl font-bold md:text-5xl">
// 							Lorem ipsum dolor sit amet consectetur.
// 						</h1>
// 						<p className="mt-8 font-medium">
// 							Lorem ipsum dolor sit amet consectetur adipisicing elit.
// 							Laboriosam magni, rem sed sint neque doloribus saepe veniam minima
// 							quaerat minus.
// 						</p>
// 						<div className="mt-8 flex w-full max-w-sm items-center space-x-2">
// 							<input
// 								className="flex h-10 w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
// 								type="email"
// 								placeholder="Email"
// 							></input>
// 							<button
// 								type="button"
// 								className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 							>
// 								Subscribe
// 							</button>
// 						</div>
// 					</div>
// 					<div className="flex flex-col items-center justify-center md:flex-row lg:col-span-7">
// 						<div className="w-full p-5 md:w-1/2">
// 							<div className="rounded-md border bg-white bg-opacity-90">
// 								<div className=" border-b">
// 									<div className="px-9 py-7">
// 										<h3 className="mb-3 text-xl font-bold leading-snug text-gray-900">
// 											Standard
// 										</h3>
// 										<p className="font-medium leading-relaxed text-gray-500">
// 											Lorem ipsum dolor sit amet, consect etur adipiscing maror.
// 										</p>
// 									</div>
// 								</div>
// 								<div className="px-9 pb-9 pt-8">
// 									<p className="mb-6 font-medium leading-relaxed text-gray-600">
// 										Features included:
// 									</p>
// 									<ul className="mb-11">
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												3 Team Members
// 											</p>
// 										</li>
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												1200+ UI Blocks
// 											</p>
// 										</li>
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												10 GB Cloud Storage
// 											</p>
// 										</li>
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												Individual Email Account
// 											</p>
// 										</li>
// 										<li className="flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												Premium Support
// 											</p>
// 										</li>
// 									</ul>
// 									<p className="mb-6 text-lg font-semibold leading-normal text-gray-600">
// 										<span>Starting from</span>
// 										<span className="ml-2 text-gray-900">$49/mo</span>
// 									</p>
// 									<div className="md:inline-block">
// 										<button
// 											type="button"
// 											className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 										>
// 											Start your free trial
// 										</button>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 						<div className="w-full p-5 md:w-1/2">
// 							<div className="rounded-md border bg-white bg-opacity-90">
// 								<div className=" border-b">
// 									<div className="px-9 py-7">
// 										<h3 className="mb-3 text-xl font-bold leading-snug text-gray-900">
// 											Standard
// 										</h3>
// 										<p className="font-medium leading-relaxed text-gray-500">
// 											Lorem ipsum dolor sit amet, consect etur adipiscing maror.
// 										</p>
// 									</div>
// 								</div>
// 								<div className="px-9 pb-9 pt-8">
// 									<p className="mb-6 font-medium leading-relaxed text-gray-600">
// 										Features included:
// 									</p>
// 									<ul className="mb-11">
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												3 Team Members
// 											</p>
// 										</li>
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												1200+ UI Blocks
// 											</p>
// 										</li>
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												10 GB Cloud Storage
// 											</p>
// 										</li>
// 										<li className="mb-4 flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												Individual Email Account
// 											</p>
// 										</li>
// 										<li className="flex items-center">
// 											<CheckCircle className="mr-2" size={16} />
// 											<p className="font-semibold leading-normal">
// 												Premium Support
// 											</p>
// 										</li>
// 									</ul>
// 									<p className="mb-6 text-lg font-semibold leading-normal text-gray-600">
// 										<span>Starting from</span>
// 										<span className="ml-2 text-gray-900">$49/mo</span>
// 									</p>
// 									<div className="md:inline-block">
// 										<button
// 											type="button"
// 											className="rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 										>
// 											Start your free trial
// 										</button>
// 									</div>
// 								</div>
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</div>

// 			{/* NewsLetter */}
// 			<div className="mx-auto max-w-7xl bg-gray-50 px-2 py-10 lg:px-2">
// 				<div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
// 					<div className="w-full md:w-2/3 lg:w-1/2">
// 						<h2 className="text-3xl font-bold text-black">
// 							Sign up for our weekly newsletter
// 						</h2>
// 						<p className="mt-4 text-gray-600">
// 							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
// 							at ipsum eu nunc commodo posuere et sit amet ligula.
// 						</p>
// 						<div className="mt-4">
// 							<p className="font-semibold text-gray-800">
// 								Trusted by over 100,000+ businesses and individuals
// 							</p>
// 							<div className="mt-2 flex items-center">
// 								<div className="flex space-x-1">
// 									{Array.from({ length: 5 }).map((_, i) => (
// 										<Star key={i} className="h-5 w-5 text-yellow-400" />
// 									))}
// 								</div>
// 								<span className="ml-2 inline-block">
// 									<span className="text-sm font-semibold text-gray-800">
// 										4.8/5 . 3420 Reviews
// 									</span>
// 								</span>
// 							</div>
// 						</div>
// 					</div>
// 					<div className="mt-10 w-full md:w-2/3 lg:mt-0 lg:w-1/2">
// 						<form className="flex lg:justify-center">
// 							<div className="flex w-full max-w-md flex-col space-y-4">
// 								<input
// 									className="flex h-10 w-full rounded-md border border-black/30 bg-transparent px-3 py-2 text-sm placeholder:text-gray-600 focus:outline-none focus:ring-1 focus:ring-black/30 focus:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50"
// 									type="email"
// 									placeholder="Email"
// 								></input>
// 								<button
// 									type="button"
// 									className="w-full rounded-md bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-black/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-black"
// 								>
// 									Subscribe
// 								</button>
// 							</div>
// 						</form>
// 						<p className="mt-2 lg:text-center">
// 							<span className="text-sm text-gray-600">
// 								By signing up, you agree to our terms of service and privacy
// 								policy.
// 							</span>
// 						</p>
// 					</div>
// 				</div>
// 			</div>

// 			{/* footer */}
// 			<div className="mx-auto mt-12 max-w-7xl">
// 				<footer className="px-4 py-10">
// 					<div className="flex flex-col md:flex-row md:items-center">
// 						<span>
// 							<svg
// 								width="40"
// 								height="46"
// 								viewBox="0 0 50 56"
// 								fill="none"
// 								xmlns="http://www.w3.org/2000/svg"
// 							>
// 								<path
// 									d="M23.2732 0.2528C20.8078 1.18964 2.12023 12.2346 1.08477 13.3686C0 14.552 0 14.7493 0 27.7665C0 39.6496 0.0986153 41.1289 0.83823 42.0164C2.12023 43.5449 23.2239 55.4774 24.6538 55.5267C25.9358 55.576 46.1027 44.3832 48.2229 42.4602C49.3077 41.474 49.3077 41.3261 49.3077 27.8158C49.3077 14.3055 49.3077 14.1576 48.2229 13.1714C46.6451 11.7415 27.1192 0.450027 25.64 0.104874C24.9497 -0.0923538 23.9142 0.00625992 23.2732 0.2528ZM20.2161 21.8989C20.2161 22.4906 18.9835 23.8219 17.0111 25.3997C15.2361 26.7803 13.8061 27.9637 13.8061 28.0623C13.8061 28.1116 15.2361 29.0978 16.9618 30.2319C18.6876 31.3659 20.2655 32.6479 20.4134 33.0917C20.8078 34.0286 19.871 35.2119 18.8355 35.2119C17.8001 35.2119 9.0233 29.3936 8.67815 28.5061C8.333 27.6186 9.36846 26.5338 14.3485 22.885C17.6521 20.4196 18.4904 20.0252 19.2793 20.4196C19.7724 20.7155 20.2161 21.3565 20.2161 21.8989ZM25.6893 27.6679C23.4211 34.9161 23.0267 35.7543 22.1391 34.8668C21.7447 34.4723 22.1391 32.6479 23.6677 27.9637C26.2317 20.321 26.5275 19.6307 27.2671 20.3703C27.6123 20.7155 27.1685 22.7864 25.6893 27.6679ZM36.0932 23.2302C40.6788 26.2379 41.3198 27.0269 40.3337 28.1609C39.1503 29.5909 31.6555 35.2119 30.9159 35.2119C29.9298 35.2119 28.9436 33.8806 29.2394 33.0424C29.3874 32.6479 30.9652 31.218 32.7403 29.8867L35.9946 27.4706L32.5431 25.1532C30.6201 23.9205 29.0915 22.7371 29.0915 22.5892C29.0915 21.7509 30.2256 20.4196 30.9159 20.4196C31.3597 20.4196 33.6771 21.7016 36.0932 23.2302Z"
// 									fill="black"
// 								/>
// 							</svg>
// 						</span>
// 						<div className="mt-4 grow md:ml-12 md:mt-0">
// 							<p className="text-base font-semibold text-gray-700">
// 								© 2023 DevUI Component Library
// 							</p>
// 						</div>
// 					</div>
// 					<div className="mt-16 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
// 						<div className="mb-8 lg:mb-0">
// 							<p className="mb-6 text-lg font-semibold text-gray-700">
// 								Company
// 							</p>
// 							<ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
// 								<li>About us</li>
// 								<li>Company History</li>
// 								<li>Our Team</li>
// 								<li>Our Vision</li>
// 								<li>Press Release</li>
// 							</ul>
// 						</div>
// 						<div className="mb-8 lg:mb-0">
// 							<p className="mb-6 text-lg font-semibold text-gray-700">
// 								Our Stores
// 							</p>
// 							<ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
// 								<li>Washington</li>
// 								<li>New Hampshire</li>
// 								<li>Maine</li>
// 								<li>Texas</li>
// 								<li>Indiana</li>
// 							</ul>
// 						</div>
// 						<div className="mb-8 lg:mb-0">
// 							<p className="mb-6 text-lg font-semibold text-gray-700">
// 								Services
// 							</p>
// 							<ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
// 								<li>UI / UX Design</li>
// 								<li>App Development</li>
// 								<li>API reference</li>
// 								<li>API status</li>
// 								<li>Documentation</li>
// 							</ul>
// 						</div>
// 						<div className="mb-8 lg:mb-0">
// 							<p className="mb-6 text-lg font-semibold text-gray-700">Legal</p>
// 							<ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
// 								<li>Privacy Policy</li>
// 								<li>Terms of Service</li>
// 								<li>Cookie Policy</li>
// 								<li>Disclaimer</li>
// 								<li>Media Policy</li>
// 							</ul>
// 						</div>
// 						<div className="mb-8 lg:mb-0">
// 							<p className="mb-6 text-lg font-semibold text-gray-700">
// 								Social Links
// 							</p>
// 							<ul className="flex flex-col space-y-4 text-[14px] font-medium text-gray-500">
// 								<li>Facebook</li>
// 								<li>Twitter</li>
// 								<li>Instagram</li>
// 								<li>Linkedin</li>
// 								<li>YouTube</li>
// 							</ul>
// 						</div>
// 					</div>
// 				</footer>
// 			</div>
// 		</div>
// 	);
// }

// export default LandinngPage;

import React, { useEffect } from "react";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router";

const LandinngPage = () => {
	const nav = useNavigate();
	useEffect(() => {
		nav("/Dashboard");
	}, []);

	return <Loader />;
};

export default LandinngPage;
