import React, { useEffect, useState } from "react";
import styles from "./CustomerList.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for styling

import { ALL_CATEGORY_API, DELETE_CATEGORY_API } from "../../utils/api";
import {
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
	showToast,
} from "../../utils/constants";
import axiosInstance from "../../utils/axios";
import { logger } from "../../utils/logger";
import GenericTable from "../../components/GenericTable";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router";

const CategoryList = () => {
	const navigate = useNavigate();

	const [activeIndex, setActiveIndex] = useState(0);
	const [search, setSearch] = useState("");
	const [visible, setVisible] = useState(false);
	const [categories, setCategories] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const fetchData = async () => {
		try {
			setIsLoading(true);
			const { data } = await axiosInstance.get(ALL_CATEGORY_API);
			if (data?.error) {
				showToast(data?.message || SOMETHING_WENT_WRONG, "error");
			} else {
				logger.log({
					data: data,
				});
				setCategories(data?.payload);
			}
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG, "error");
		} finally {
			setIsLoading(false);
		}
	};

	const handleDeleteCategory = async (categoryId) => {
		try {
			const { data } = await axiosInstance.delete(
				`${DELETE_CATEGORY_API}/${categoryId}`
			);
			if (data?.error)
				showToast(data?.message || SOMETHING_WENT_WRONG, "error");

			logger.log({
				data: data,
			});

			showToast(data?.message, "success");
			fetchData();
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG, "error");
		}
	};

	const handleEditCategory = async (categoryId) => {
		// Implement your edit logic here
		navigate("/category/add", { state: { categoryId } });
	};

	useEffect(() => {
		fetchData();
	}, []);

	const handleSubmit = (e) => {
		
	};

	
	return (
		<>
			<Card
				className={styles.card}
				title="Category"
				classTitle={cn("title-purple", styles.title)}
				classCardHead={cn(styles.head, { [styles.hidden]: visible })}
				head={
					<>
						<Form
							className={styles.form}
							value={search}
							setValue={setSearch}
							onSubmit={() => handleSubmit()}
							placeholder="Search by name or email"
							type="text"
							name="search"
							icon="search"
						/>
					</>
				}
			>
				{isLoading ? (
					<Loading color="red" />
				) : (
					<GenericTable
						data={categories}
						handleDelete={handleDeleteCategory}
						onEdit={handleEditCategory}
					/>
				)}

				{/* <Details className={styles.details} onClose={() => setVisible(false)} /> */}
			</Card>

			{/* Toast container for displaying messages */}
			<ToastContainer />
		</>
	);
};

export default CategoryList;
