/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	SELLER_DETAIL_API,
	APPROVE_SELLER_API,
	BAN_USER,
	SELLER_DOCUMENT_ISSUE,
} from "../../utils/api";
import axiosInstance from "../../utils/axios";
import {
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
	showToast,
} from "../../utils/constants";
import { logger } from "../../utils/logger";

const SellerDetail = () => {
	const { id } = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [seller, setSeller] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [previewFile, setPreviewFile] = useState(null);
	const [nname, setName] = useState(null);
	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);
	const toggleModal = () => setShowModal((prev) => !prev);
	const formatDate = (dateString) => {
		const options = { year: "numeric", month: "long", day: "numeric" };
		return new Date(dateString).toLocaleDateString(undefined, options);
	};

	useEffect(() => {
		axiosInstance
			.get(`${SELLER_DETAIL_API}${id}`)
			.then((response) => {
				setSeller(response.data?.payload?.seller);
				setName(response.data?.payload?.name);
				setIsLoading(false);
			})
			.catch((error) => {
				console.error("Error fetching product details:", error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [id]);

	const toggleSellerStatus = () => {
		if (!seller) return;

		const newStatus = seller.status === "Approved" ? "Banned" : "Approved";

		let endpoint;
		let data = {};
		let method = "get"; // Defaulting to GET method

		if (newStatus === "Banned") {
			endpoint = `${BAN_USER}/${id}`;
			data = { userId: seller._id };
			console.clear();
			console.log(endpoint);
			method = "post"; // Setting to POST for the banning process
		} else {
			endpoint = `${APPROVE_SELLER_API}/${seller._id}`;
			data = { status: newStatus }; // In reality, for a GET request, this wouldn't be sent, but kept for clarity.
		}

		const makeRequest = () => {
			if (method === "get") {
				return axiosInstance.get(endpoint);
			} else if (method === "post") {
				return axiosInstance.post(endpoint, data);
			}
		};

		makeRequest()
			.then((response) => {
				setSeller({
					...seller,
					status: newStatus,
				});
			})
			.catch((error) => {
				console.error(`Error setting seller status to ${newStatus}:`, error);
			});
	};
	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
		setPageNumber(1);
	};

	const handlePreview = (fileSrc) => {
		setPreviewFile(fileSrc);
	};

	const documentIssue = async () => {
		try {
			const { data } = await axiosInstance.post(
				`${SELLER_DOCUMENT_ISSUE}${id}`
			);
			if (data?.error) return showToast(data?.message || SOMETHING_WENT_WRONG);
			logger.log({
				data: data,
			});
			showToast(data?.message || SOMETHING_WENT_WRONG, TOAST_SUCCESS);
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG);
		}
	};

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-screen">
				Loading...
			</div>
		);
	}
	if (!seller)
		return (
			<div className="flex justify-center items-center h-screen">
				Seller has not created profile
			</div>
		);

	return (
		<section className="bg-white dark:bg-gray-200 p-10">
			<div className="grid grid-cols-1 gap-10 justify-center items-between lg:grid-cols-3">
				<div className="bg-gray-100 p-6 rounded-xl shadow-md">
					<div className="flex flex-col justify-center items-center text-xl space-y-4">
						<div className="flex items-center space-x-2">
							<span className="font-bold text-gray-700 text-lg">Status : </span>
							<span
								className={`px-2 rounded ${
									seller?.status === "Approved"
										? "bg-green-600 text-white"
										: "bg-red-600 text-white"
								}`}
							>
								{seller?.status}
							</span>
						</div>
						{seller?.status === "Approved" ? (
							<button
								onClick={toggleSellerStatus}
								className="bg-red-700 text-white px-10 py-2 rounded-full hover:bg-red-900 transition duration-300 focus:outline-none"
							>
								Ban Seller
							</button>
						) : (
							<>
								<button
									onClick={toggleSellerStatus}
									className="bg-green-500 text-white px-10 py-2 rounded-full hover:bg-green-600 transition duration-300 focus:outline-none"
								>
									Approve Seller
								</button>
							</>
						)}
					</div>

					<div className="grid grid-cols-1 gap-4 mt-20 ">
						<button
							onClick={documentIssue}
							className="bg-yellow-500 text-white px-4 py-2 rounded-full hover:bg-yellow-600 transition duration-300 focus:outline-none"
						>
							Document Issue!
						</button>
						<a
							onClick={toggleModal}
							className="flex justify-center bg-pink-600 text-white px-4 py-2 rounded-full hover:bg-pink-700 transition duration-300 cursor-pointer focus:outline-none"
						>
							View Document
						</a>
					</div>
					{showModal && (
						<DocumentModal
							documents={[
								seller?.commercialLicense,
								seller?.identityProof,
								seller?.bannerUrl,
							]}
							oldDocuments={seller?.oldDocs}
							onClose={toggleModal}
							onPreview={handlePreview}
						/>
					)}
				</div>
				{previewFile &&
					(previewFile?.endsWith(".pdf") ? (
						<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-60 bg-black bg-opacity-60">
							<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-60 bg-black bg-opacity-60"></div>
						</div>
					) : (
						<div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-60 bg-black bg-opacity-60">
							<img
								src={previewFile}
								alt="Preview"
								className="max-w-2xl max-h-screen object-contain cursor-pointer rounded-xl shadow-2xl"
								onClick={() => setPreviewFile(null)}
							/>
						</div>
					))}
				<div className="grid grid-cols-1 gap-6 lg:col-span-2 sm:grid-cols-2">
					{[
						{
							title: "Seller Name",
							value: `${nname}`,
						},
						{
							title: "Seller ID",
							value: seller?._id,
						},
						{
							title: "Seller Contact",
							value: (
								<>
									<p className="mt-2 text-gray-800">
										{seller?.customerCareEmail}
									</p>
									<p className="mt-2 text-gray-800">
										{seller?.customerCareNumber}
									</p>
								</>
							),
						},
						{
							title: "Seller Address",
							value: seller?.address,
						},
						{
							title: "Seller Business Name",
							value: seller?.businessName,
						},
						{
							title: "Commercial License Expiry Date",
							value: seller?.commercialLicenseExpiryDate
								? formatDate(seller?.commercialLicenseExpiryDate)
								: "N/A", // Format the date
						},
					].map((item, idx) => (
						<div
							key={idx}
							className="bg-gray-100 p-6 rounded-xl shadow-md space-y-2"
						>
							<h2 className="font-medi dark:text-gray-900 text-xl">
								{item?.title}
							</h2>
							<div className="mt-2 text-gray-800">{item?.value}</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};
const DocumentModal = ({ documents, onClose, oldDocuments }) => {
	const isPDF = (document) => document && document?.endsWith(".pdf");

	// function to open the pdf in new tab
	const previewPdf = (url) => {
		const viewerUrl = `https://docs.google.com/gview?embedded=true&url=${encodeURIComponent(
			url
		)}`;
		window.open(viewerUrl, "_blank");
	};

	// Function to render the documents or a 'no documents found' message
	const renderDocuments = () => {
		if (!documents || documents.length === 0) {
			return <p>No documents found.</p>;
		}

		return documents.map((doc, idx) => (
			<div
				key={idx}
				className="border border-gray-300 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 relative"
			>
				<div className="relative">
					{isPDF(doc) ? (
						<>
							<div className="w-full h-48 flex items-center justify-center bg-gray-200 rounded-t-lg">
								{/* <span className="text-lg font-bold">PDF</span> */}
								<img
									src="/pdf.png"
									alt={`Document ${idx + 1}`}
									className=" h-28 rounded-lg object-cover object-center"
								/>
							</div>

							<button
								onClick={() => previewPdf(doc)}
								className="flex items-center bg-blue-500 text-white px-2 py-2 rounded-full hover:bg-blue-600 focus:outline-none absolute top-0 right-0"
								title="Preview PDF"
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-6 h-6"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
									/>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
									/>
								</svg>
								{/* Preview */}
							</button>
						</>
					) : (
						<img
							src={doc}
							alt={`Document ${idx + 1}`}
							className="w-full h-48 object-cover rounded-t-lg"
						/>
					)}
					<div className="absolute top-0 left-0 m-2 px-2 bg-gray-600 text-white rounded-full">
						{idx + 1}
					</div>
				</div>
				<div className="p-4 space-y-3">
					<div className="flex justify-between items-center">
						<div className="space-x-3">
							<a
								href={doc}
								download
								className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300 focus:outline-none"
							>
								Download
							</a>
						</div>
					</div>
				</div>
			</div>
		));
	};

	const renderUpdatedDocuments = () => {
		if (!oldDocuments || oldDocuments.length === 0) {
			return <p>No documents found.</p>;
		}

		return oldDocuments.map((doc, idx) => (
			<div
				key={idx}
				className="border-2 border-gray-600 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 relative p-1 group  bg-gray-200 mb-2"
			>
				<div className="relative">
					{isPDF(doc?.documentUrl) ? (
						<>
							<div className="w-full h-10 flex items-center justify-center bg-gray-200 rounded-t-lg">
								{/* <span className="text-lg font-bold">PDF</span> */}
								<img
									src="/pdf.png"
									alt={`Document ${idx + 1}`}
									className=" h-10 rounded-lg object-cover object-center"
								/>
							</div>
						</>
					) : (
						<img
							src={doc?.documentUrl}
							alt={`Document ${idx + 1}`}
							className="w-auto h-10 object-cover rounded-t-lg"
						/>
					)}
					{/* <div className="absolute top-0 left-0 m-2 px-2 bg-gray-600 text-white rounded-full">
						{idx + 1}
					</div> */}
					{/* <div className="w-full flex">
						<div className="flex-1">⬇️</div>
						<div className="flex-1 text-right">👁️</div>
					</div> */}
					<div className="w-full flex">
						<div className="flex-1 text-xs font-normal bg-green-500 flex justify-center items-center hover:bg-black cursor-pointer">
							{" "}
							<a href={doc?.documentUrl} download>
								Download
							</a>
						</div>
						{isPDF(doc?.documentUrl) && (
							<div
								onClick={() => previewPdf(doc?.documentUrl)}
								className="flex-1 text-xs font-normal text-right bg-gray-700 flex justify-center items-center hover:bg-black cursor-pointer"
							>
								Preview
							</div>
						)}
					</div>
				</div>
				{/* <div className="p-4 space-y-3">
					<div className="flex justify-between items-center">
						<div className="space-x-3">
							<a
								href={doc}
								download
								className="bg-green-500 text-white px-4 py-2 rounded-full hover:bg-green-600 transition duration-300 focus:outline-none"
							>
								Download
							</a>
						</div>
					</div>
				</div> */}
			</div>
		));
	};

	return (
		<div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-40">
			<div className="bg-white rounded-lg shadow-xl max-w-4xl mx-auto p-8 space-y-6">
				<div className="flex justify-between items-center">
					<h2 className="text-2xl font-bold text-gray-800">Seller Documents</h2>
					<button
						onClick={onClose}
						className="p-2 rounded-md text-gray-600 hover:bg-gray-200 transition duration-300"
					>
						{/* SVG close button */}
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M6 18L18 6M6 6l12 12"
							/>
						</svg>
					</button>
				</div>
				<div className="w-full">
					<div className="text-gray-700"># Current Documents</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
					{renderDocuments()}
				</div>

				{Array.isArray(oldDocuments) && oldDocuments?.length !== 0 && (
					<>
						<div className="w-full">
							<div className="text-gray-700"># Updated Documents</div>
						</div>
						<div className="grid grid-cols-1 md:grid-cols-5  space-x-2">
							{renderUpdatedDocuments()}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default SellerDetail;
