import React, { useState, useEffect } from 'react';
import axiosInstance from '../../utils/axios';
import { useParams } from 'react-router-dom';
import { GET_USER_DETAILS } from '../../utils/api';

const OrderDetailPage = () => {
    const { id } = useParams();
    const [orderDetail, setOrderDetail] = useState(null);

    useEffect(() => {
        const fetchOrderDetail = async () => {
            try {
                const response = await axiosInstance.get(`${GET_USER_DETAILS}/${id}`);
                setOrderDetail(response.data.payload);
            } catch (error) {
                console.error("Error fetching order details:", error);
            }
        };

        fetchOrderDetail();
    }, [id]);

    if (!orderDetail) return <p className="text-center mt-4 text-xl font-bold">Loading...</p>;

    const { user, orders } = orderDetail;

    return (
        <div className="p-6 space-y-8">
    {/* User Details */}
    <div className="grid grid-cols-1 md:grid-cols-4 gap-6 p-6 rounded shadow-lg bg-white">
        <div className="flex items-center justify-center">
            <img src={user.profile.profilePic} alt="User Profile" className="rounded-full w-24 h-24" />
        </div>
        <div>
            <h2 className="text-xl font-bold mb-3">Name</h2>
            <p className="text-gray-700">{user.profile.firstName} {user.profile.lastName}</p>
        </div>
        <div>
            <h2 className="text-xl font-bold mb-3">User Details</h2>
            <p className="text-gray-700">Email: {user.email}</p>
            <p className="text-gray-700">Mobile: {user.mobileNumber}</p>
        </div>
        <div>
            <h2 className="text-xl font-bold mb-3">Created At</h2>
            <p className="text-gray-700">{new Date(user.profile.createdAt).toLocaleDateString()}</p>
        </div>
    </div>

    {/* Orders */}
    {orders.map((order, index) => (
        <div key={index} className="p-6 rounded shadow-lg bg-white space-y-6">
            <span className="inline-block text-xl font-bold mb-2 py-2 px-4 bg-pink-600 text-white rounded-full">Order ID: {order.orderId}</span>

            {order.cart.map((cartItem, cartIndex) => (
                <div key={cartIndex} className="grid grid-cols-1 md:grid-cols-3 gap-6 p-4 border rounded bg-gray-100">
                    <img src={cartItem?.variant?.thumbnailImage} alt="Product Thumbnail" className="w-40 h-40 rounded mx-auto md:mx-0" />
                    <div className="space-y-2 md:col-span-2">
                        <p className="text-lg font-semibold">{cartItem?.product?.name}</p>
                        <p className="text-gray-700">{cartItem?.product?.description}</p>
                        <div className="pt-4 border-t">
                          
							<p className="text-gray-600">MarketPrice: {cartItem?.current_marketPrice}</p>
							<p className="text-gray-600">SellingPrice: {cartItem?.current_sellingPrice}</p>
							<p className="text-gray-600">DiscountPrice: {cartItem?.current_discountPrice}</p>
							<p className="text-gray-600">Purchase: {cartItem?.purchasePrice}</p>
                            <p className="text-gray-600">Quantity: {cartItem?.quantity}</p>
                            <p>Payment Date: {new Date(order.payment.created).toLocaleDateString()}</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    ))}
</div>

    );
};

export default OrderDetailPage;
