export const BASE_URL = 'https://makeitless-backend.onrender.com/api';
// export const BASE_URL = "https://homeservice.ashadnasim.com/api";
// export const BASE_URL = "http://localhost:8000/api";

const token = localStorage.getItem("token");
export const HEADER = {
	timeout: 60000,
	headers: {
		Authorization: `Bearer ${token}`,
		Accept: "application/json",
	},
};
export const CREATE_SELLER = "/v1/seller/create";
export const SELLER_SIGNIN = "/v1/auth/admin/login";
export const CREATE_ADMIN = "/v1/admin/create";
export const ADMIN_SIGNIN = "/v1/admin/login";
export const ADD_CATEGORY_API = "/v1/category/add";
export const DETAIL_CATEGORY_API = "/v1/category/detail";
export const ALL_CATEGORY_API = "/v1/category/get/all";

export const GET_ALL_SUB_CATEGORY = "/v1/subcategory/get/all";
export const GET_SUB_CATEGORY = "/v1/subcategory/get";
export const ADD_SUB_CATEGORY = "/v1/subcategory/add";
export const EDIT_SUB_CATEGORY = "/v1/subcategory/edit";
export const DELETE_SUB_CATEGORY = "/v1/subcategory/delete";

export const EDIT_CATEGORY_API = "/v1/category/edit";
export const DELETE_CATEGORY_API = "/v1/category/remove";
export const ADD_BANNER_API = "/v1/banner/add";
export const ALL_BANNER_API = "/v1/banner/get/all";
export const EDIT_BANNER_API = "/v1/banner/edit";
export const DELETE_BANNER_API = "/v1/banner/remove";
export const ADD_EMPLOEE = "/v1/admin/create/employee";
export const EMPLOEE_LIST = "/v1/admin/all/employee";
// export const ADD_SELLER = "/v1/admin/create/employee";
export const SELLER_LIST = "/v1/employee/all/seller";
export const SELLER_DETAIL_API = "/v1/employee/seller/detail/";
export const SELLER_DOCUMENT_ISSUE = "/v1/employee/seller/document-issue/";
export const SELLER_APPROVAL_LIST = "/v1/employee/approve/seller/:id";
export const BAN_USER = "/v1/employee/ban/user";
export const APPROVE_SELLER_API = "/v1/employee/approve/seller";
export const USER_LIST = "/v1/employee/all/website-user";
// helper
export const IMAGE_UPLOAD_API = "/v1/helper/image/upload";
export const ALL_BIDS = "/v1/employee/all-bids";
export const ALL_ORDERS = "/v1/employee/all-orders";
export const ALL_REVIEWS = "/v1/employee/all/reviews";
export const REVIEWS_DETAIL = "/v1/employee/review/";

export const PRODUCT_DETAIL = "/v1/product/detail/:id";
export const ALL_PRODUCT = "/v1/employee/products/all";
export const ALL_PRODUCT_DETAIL = "/v1/employee/product/detail/:id";
export const GET_USER = "/v1/employee/customer/all";
export const GET_USER_DETAILS = "/v1/employee/customer/detail";
export const DELETE_PRODUCT = "/v1/employee/product/delete";
export const DELETE_VARIANT = "/v1/employee/variant/delete";
export const DELETE_SELLER = "/v1/employee/seller/delete";
export const ORDER_DETAIL = "/v1/employee/order/detail";
export const UPDATE_ORDER_STATUS = "/v1/employee/order/update-status";
export const UPDATE_TRACKING_STATUS =
	"/v1/employee/order/update-tracking-status";
export const ALL_QUERIES = "/v1/enquiry/get-all";
