import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { ALL_QUERIES } from "../../utils/api";

const AllQueries = () => {
	const [queries, setQueries] = useState([]);

	useEffect(() => {
		axiosInstance
			.get(ALL_QUERIES)
			.then(function (response) {
				// Handle success
				console.log("Data retrieved:", response.data);
				setQueries(response.data.payload.docs);
			})
			.catch(function (error) {
				// Handle error
				console.error("Error fetching data:", error);
			});
	}, []);

	return (
		<table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
			<thead className="bg-pink-600">
				<tr>
					<th className="text-left py-3 px-4 uppercase font-semibold text-lg text-white">
						First Name
					</th>
					<th className="text-left py-3 px-4 uppercase font-semibold text-lg text-white">
						Email
					</th>
					<th className="text-left py-3 px-4 uppercase font-semibold text-lg text-white">
						Message
					</th>
				</tr>
			</thead>
			<tbody className="text-gray-700">
				{queries.map((query, index) => (
					<tr key={query._id} className={index % 2 === 0 ? "bg-gray-100" : ""}>
						<td className="text-left font-semibold py-3 px-4">
							{query.firstName}
						</td>
						<td className="text-left font-semibold py-3 px-4">{query.email}</td>
						<td className="text-left font-semibold py-3 px-4">
							{query.message}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default AllQueries;
