import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { REVIEWS_DETAIL } from "../../utils/api";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ReviewDetail = () => {

    const [review, setReview] = useState(null);
    const { reviewId } = useParams();
    const navigate = useNavigate();
	useEffect(() => {
		axiosInstance
			.get(`${REVIEWS_DETAIL}${reviewId}/detail`)
			.then((response) => {
				setReview(response?.data?.payload);
			})
			.catch((error) => {
				console.error("Error fetching review details:", error);
			});
	}, [reviewId]);
	const handleApproveReview = () => {
		if (review) {
			// Create a new object that includes the review data in the request body
			const requestData = {
				status: true,
				isRecommended: true,
				isShowInHomePage: true,
				responseData: review, // Include the review data
			};

			// Send a POST request to the approval API endpoint with the new requestData
			axiosInstance
				.post(`/v1/employee/review/${reviewId}/change-status`, requestData)
				.then((response) => {
					// Handle success, e.g., show a success message
					console.log("Review approved successfully:", response?.data);
					navigate("/allreviews");
				})
				.catch((error) => {
					// Handle error, e.g., show an error message
					console.error("Error approving review:", error);
				});
		}
	};

	if (!review) {
		return <div>Loading...</div>;
	}

	return (
		<div className="mx-auto mt-10 p-5 max-w-5xl">
			<h2 className="text-3xl font-semibold mb-8 border-b-2 border-gray-300 pb-4">
				Review Detail
			</h2>

			<div className="bg-white p-8 shadow-xl rounded-lg grid grid-cols-1 md:grid-cols-2 gap-8">
				<div className="border bg-slate-100 p-4 rounded-lg">
					<h3 className="text-2xl font-semibold mb-4 border-b-2 border-gray-200 pb-2">
						User Details
					</h3>
					<div className="flex items-center  space-x-6">
						<img
							src={review?.userImage || "defaultImage.jpg"}
							alt="User"
							className="w-24 h-24 rounded-full shadow-lg"
						/>
						<div>
							<h4 className="text-xl font-medium">
								{review?.user?.profile?.firstName}{" "}
								{review?.user?.profile?.lastName}
							</h4>
							<p className="text-base text-gray-600 mt-2">
								<strong>Email:</strong> {review?.user?.email}
							</p>
							<p className="text-base text-gray-600 mt-2">
								<strong>Mobile:</strong> {review?.user?.mobileNumber}
							</p>
						</div>
					</div>
				</div>
				<div className="border p-4 bg-slate-100 p-5 rounded-lg col-span-full">
					<h3 className="text-2xl font-semibold mb-4 border-b-2 border-gray-200 pb-2">
						Product Details
					</h3>
					<p className="text-base text-gray-600">
						<strong>Name:</strong> {review?.product?.name}
					</p>
					<p className="text-base text-gray-600">
						<strong>Description:</strong> {review?.product?.description}
					</p>
					<p className="text-base text-gray-600">
						<strong>Brand:</strong> {review?.product?.brand}
					</p>
					<p className="text-base text-gray-600">
						<strong>Features:</strong> {review?.product?.features?.join(", ")}
					</p>
				</div>
				<div className="bg-slate-100 p-5">
					<img
						src={review?.variant?.thumbnailImage}
						alt="Product"
						className="w-60 h-60 rounded-lg shadow-md mb-4"
					/>
					<p>
						<strong>SKU:</strong> {review?.variant?.SKU}
					</p>
					<p>
						<strong>Price:</strong> {review?.variant?.price?.sellingPrice}
					</p>
					{review?.variant?.shippingDetails &&
						review?.variant?.shippingDetails?.weight && (
							<p>
								<strong>Weight:</strong>{" "}
								{review?.variant?.shippingDetails?.weight}
							</p>
						)}
				</div>
				<div className="border p-5 bg-slate-100 rounded-lg">
					<h3 className="text-2xl font-semibold mb-4 border-b-2 border-gray-200 pb-2">
						Review Details
					</h3>
					<div className="bg-white p-5">
						<p className="text-sm text-gray-500">{review?.title}</p>
						<p className="text-sm text-gray-500 mt-2">{review?.description}</p>
					</div>
					<div className="flex justify-between items-center mt-4">
						<div className="flex space-x-2 items-center">
							<span className="text-gray-600 font-medium">
								{[...Array(review?.count)].map((_, index) => (
									<span key={index} className="text-yellow-500 text-3xl">
										&#9733;
									</span>
								))}
							</span>
						</div>
					</div>{" "}
					<div className="flex justify-between items-center mt-4">
						<button
							onClick={handleApproveReview}
							className={`inline-block py-2 px-4 rounded-full text-sm font-medium ${
								review?.status === "Waiting Approval"
									? "bg-yellow-300 text-yellow-800"
									: "bg-green-300 text-green-800"
							}`}
						>
							Approve Review
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReviewDetail;
