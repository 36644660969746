// utils
import store from "../store";
import { setAuthentication } from "../store/globalSlice";
import axios from "./axios";
import axiosInstance from "./axios";
import { TOKEN, showToast } from "./constants";
import jwt_decode from "jwt-decode";
import { logger } from "./logger";

// ----------------------------------------------------------------------

function jwtDecode(token) {
	const base64Url = token.split(".")[1];
	const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	const jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split("")
			.map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
			.join("")
	);

	return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

const isValidToken = (token) => {
	if (!token) {
		return false;
	}
	return true;
};

// ---------------------------------------------------------------------

// ----------------------------------------------------------------------

// const setSession = (accessToken) => {
//   if (accessToken) {
//     localStorage.setItem('token', accessToken);

//     axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

//     // This function below will handle when token is expired
//     const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
//     tokenExpired(exp);
//   } else {
//     localStorage.removeItem('token');

//     delete axios.defaults.headers.common.Authorization;
//   }
// };

const verifyToken = (token) => {
	if (token) {
		let decodedToken = jwt_decode(token);
		logger.log("Decoded Token", decodedToken);
		let currentDate = new Date();

		// JWT exp is in seconds
		if (decodedToken.exp * 1000 < currentDate.getTime()) {
			logger.log("Token expired.");
			return false;
		} else {
			logger.log("Valid token");
			return true;
		}
	}
	return false;
};

const setSession = (token) => {
	logger.log("Called");
	const isTokenValid = verifyToken(token);
	if (!isTokenValid) {
		localStorage.removeItem(TOKEN);
		delete axios.defaults.headers.common.Authorization;
		// window.location.replace("/login");
		return showToast("Token Expired, please signin again.");
	}
	if (token) {
		localStorage.setItem(TOKEN, token);
		axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		store.dispatch(setAuthentication(true));
	} else {
		localStorage.removeItem(TOKEN);
		delete axios.defaults.headers.common.Authorization;
	}
};
export { isValidToken, setSession, verifyToken };
