import React, { useEffect, useState } from "react";
import styles from "./CustomerList.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for styling

import Table from "./Table";
import Details from "./Details";
import {
	ALL_BANNER_API,
	DELETE_BANNER_API,
} from "../../utils/api";
import {
  SOMETHING_WENT_WRONG,
  TOAST_SUCCESS,
} from "../../utils/constants";
import axiosInstance from "../../utils/axios";
import { logger } from "../../utils/logger";

const navigation = ["Active", "New"];

const CategoryList = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [banner, setBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosInstance.get(ALL_BANNER_API);
      if (data?.error) {
        showToast(data?.message || SOMETHING_WENT_WRONG, "error");
      } else {
        logger.log({
          data: data,
        });
        setBanner(data?.payload);
      }
    } catch (error) {
      console.log({ error });
      showToast(error?.response?.message || SOMETHING_WENT_WRONG, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteBanner = async (bannerId) => {
	try {
	  // Optimistic update: Remove the banner from the UI immediately
	  setBanner((prevBanner) =>
		prevBanner.filter((banner) => banner._id !== bannerId)
	  );
  
	  // Send the delete request to the server
	  const { data } = await axiosInstance.delete(
		`${DELETE_BANNER_API}/${bannerId}`
	  );
  
	  if (data?.error) {
		showToast(data?.message || SOMETHING_WENT_WRONG, "error");
  
		// If the server request fails, you can revert the UI update here
		// Example: Reload the data
		fetchData();
	  } else {
		logger.log({
		  data: data,
		});
  
		showToast(TOAST_SUCCESS, "success");
	  }
	} catch (error) {
	  console.log({ error });
	  showToast(error?.response?.message || SOMETHING_WENT_WRONG, "error");
  
	  // If there's an error, you can also revert the UI update here
	  // Example: Reload the data
	  fetchData();
	}
  };
  

  const handleEditBanner = async (bannerId) => {
    // Implement your edit logic here
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = (e) => {
    // Handle your form submission logic
  };

  const showToast = (message, type) => {
    toast(message, {
      type: type === "success" ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
    });
  };

  return (
    <>
      <Card
        className={styles.card}
        title="Category"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
              onSubmit={() => handleSubmit()}
              placeholder="Search by name or email"
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <Table
          data={banner}
          onDelete={handleDeleteBanner}
          onEdit={handleEditBanner}
        />
        <Details className={styles.details} onClose={() => setVisible(false)} />
      </Card>

      {/* Toast container for displaying messages */}
      <ToastContainer />
    </>
  );
};

export default CategoryList;
