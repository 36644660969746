import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { ALL_ORDERS } from "../../utils/api";
import { useNavigate } from "react-router";

const AllOrders = () => {
	const [bids, setBids] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchAllOrders = async () => {
			try {
				const response = await axiosInstance.get(ALL_ORDERS);
				console.log("all orders...", response);

				if (
					response.status === 200 &&
					Array.isArray(response.data.payload.docs)
				) {
					setBids(response.data.payload.docs);
				} else {
					setError("Error fetching bids: " + response.statusText);
				}
			} catch (error) {
				setError("Error fetching bids: " + error.message);
			} finally {
				setLoading(false);
			}
		};

		fetchAllOrders();
	}, []);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error}</p>;

	return (
		<div className="max-w-full w-full mx-auto p-6 pt-0 overflow-x-auto">
			<table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
				<thead className="bg-pink-600 ">
					<tr>
						<th className="text-left py-3 px-4 uppercase font-semibold text-lg text-white">
							Transaction No.
						</th>
						{/* <th className="text-left py-3 px-4 uppercase font-semibold text-lg text-white">
							Product
						</th> */}
						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Price
						</th>
						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Booking Date
						</th>

						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Customer Name
						</th>
						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Customer Email
						</th>
						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Customer Mobile No.
						</th>
						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Payment Status
						</th>
						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Order Id
						</th>
						<th className="text-left py-3 px-2 uppercase font-semibold text-lg text-white">
							Action
						</th>
					</tr>
				</thead>
				<tbody className="text-gray-700">
					{bids.map((bid) => (
						<tr
							key={bid._id}
							className={bids.indexOf(bid) % 2 === 0 ? "bg-gray-100" : ""}
						>
							<td className="text-left font-semibold py-3 px-4">
								{bid?.payment?.transactionId}
							</td>
							<td className="text-left font-semibold py-3 px-4">
								{bid?.grandTotal} <span className="text-sm">AED</span>
							</td>
							<td className="text-left font-semibold py-3 px-4">
								{new Date(bid?.createdAt).toLocaleString()}
							</td>
							<td className="text-left font-semibold py-3 px-4">
								{bid?.user?.name}
							</td>
							<td className="text-left font-semibold py-3 px-4">
								{bid?.user?.email}
							</td>
							<td className="text-left font-semibold py-3 px-4">
								{bid?.user?.mobileNumber}
							</td>
							<td className="text-left font-semibold py-3 px-4">
								<span
									className={`px-2 py-1 ${
										bid?.paymentStatus === "COMPLETED"
											? "bg-green-100 text-green-500"
											: "bg-red-50 text-red-500"
									}`}
								>
									{bid?.paymentStatus}
								</span>
							</td>

							<td className="text-left py-3 px-4">{bid?.orderId}</td>
							<td className="text-left py-3 px-4">
								<button
									onClick={() => navigate(`/order/detail/${bid?._id}`)}
									type="button"
									className="rounded-md bg-indigo-600 px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600/80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
								>
									View
								</button>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default AllOrders;
