import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignIn.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import TextInput from "../../components/TextInput";
import Image from "../../components/Image";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"; // Import Axios
import { ADMIN_SIGNIN, SELLER_SIGNIN } from "../../utils/api";
import {
	showToast,
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
} from "../../utils/constants";
import { logger } from "../../utils/logger";
import axiosInstance from "../../utils/axios";
import { setSession } from "../../utils/jwt";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { isAdmin } from "../../utils";
import { RotatingLines } from "react-loader-spinner";

const SignIn = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const heightWindow = use100vh();
	const [formData, setFormData] = useState({ mobileNumber: "", password: "" });
	const [isLoading, setisLoading] = useState(false);
	// const [isAdmin, setIsAdmin] = useState();
	// const isAdmin = process.env.REACT_APP_WEBSITE_TYPE === "ADMIN";
	const loginUser = async (e) => {
		setisLoading(true)
		e.preventDefault();

		try {
			const { data } = await axiosInstance.post(
				isAdmin ? ADMIN_SIGNIN : SELLER_SIGNIN,
				formData
			);
			logger.log({
				data: data,
			});
			if (data?.error) return showToast(data?.message || SOMETHING_WENT_WRONG);
			showToast(data?.message, TOAST_SUCCESS);
			setSession(data?.token);
			isAdmin ? navigate("/admin-dashboard") : navigate("/dashboard");
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG);
		}finally{
			setisLoading(false)
		}
	};

	// const loginUser = (e) => {

	//   // Make a POST request to your authentication API endpoint
	//   axios
	//     .post("https://make-it-less.onrender.com/api/v1/seller/signin", formData)
	//     .then((response) => {
	//       // Handle the response from the API here
	//       const responseData = response.data;

	//       // Check if the login was successful
	//       if (responseData.success) {
	//         // Show a success toast notification
	//         toast.success(responseData.message, {
	//           position: "top-right",
	//           autoClose: 5000,
	//           hideProgressBar: false,
	//         });
	//       } else {
	//         // Show an error toast notification if login failed
	//         toast.error(responseData.message, {
	//           position: "top-right",
	//           autoClose: 5000,
	//           hideProgressBar: false,
	//         });
	//       }
	//     })
	//     .catch((error) => {
	//       // Handle errors here
	//       console.error(error);

	//       // Show an error toast notification for unexpected errors
	//       toast.error("An error occurred. Please try again.", {
	//         position: "top-right",
	//         autoClose: 5000,
	//         hideProgressBar: false,
	//       });
	//     });
	// };

	return (
		<div className={styles.login} style={{ minHeight: heightWindow }}>
			<div className={styles.wrapper}>
				<Link className={styles.logo} to="/">
					<div
						className={` h-24 w-32 text-center justify-center flex content-center items-center`}
					>
						<Image
							className={``}
							src="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
							srcDark="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
							alt="Core"
						/>
					</div>
				</Link>
				<div className={cn("h2", styles.title)}>Sign in</div>

				<div className={styles.body}>
					<form onSubmit={loginUser}
							  autoComplete="off"
					>
						<TextInput
							className={styles.field}
							name="mobileNumber"
							type="text"
							placeholder="Mobile Number"
							required
							icon="phone"
							value={formData.mobileNumber}
							onChange={(e) =>
								setFormData({ ...formData, mobileNumber: e.target.value })
							}
						/>
						<TextInput
							className={styles.field}
							name="password"
							type="password"
							placeholder="Password"
							required
							icon="lock"
							value={formData.password}
							onChange={(e) =>
								setFormData({ ...formData, password: e.target.value })
							}
						/>
						{isLoading ? <button className={cn("button", styles.button)} type="button" disabled>
							<RotatingLines
								strokeColor="white"
								strokeWidth="5"
								animationDuration="0.75"
								width="25"
								visible={true}
							/>
						</button> :
							<button className={cn("button", styles.button)} type="submit">
								Sign in
							</button>}
					</form>
					<div className={styles.note}>
						This site is protected by reCAPTCHA and the Google Privacy Policy.
					</div>
					{/* <div className={styles.info}>
						Don’t have an account?{" "}
						<Link className={styles.link} to="/sign-up">
							Sign up
						</Link>
					</div> */}
				</div>
			</div>
			{/* Add ToastContainer to display toast notifications */}
			<ToastContainer />
		</div>
	);
};

export default SignIn;
