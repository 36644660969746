import React from "react";
import cn from "classnames";
import styles from "./Loading.module.sass";

const Loading = ({ className, white }) => {
	return (
		<div className="flex justify-center align-middle mt-10">
			<div
				className={cn(styles.loader, className, {
					[styles.loaderWhite]: white,
				})}
			></div>
		</div>
	);
};

export default Loading;
