import React, { useState } from "react";
import cn from "classnames";
import { Link, useNavigate, useLocation } from "react-router-dom";
import styles from "./Panel.module.sass";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import axiosInstance from "../../utils/axios";
import { ADD_BANNER_API, EDIT_BANNER_API } from "../../utils/api"; // Assuming EDIT_BANNER_API is the endpoint for editing banners
import { SOMETHING_WENT_WRONG, TOAST_SUCCESS, showToast } from "../../utils/constants";
import GenericImageUpload from "../../screens/NewProduct/GenericImageUpload";

const BannerAdd = ({ className }) => {
    const location = useLocation();
    const bannerToEdit = location.state?.banner;
    const nav = useNavigate();

   
    const [sequence, setSequence] = useState(bannerToEdit?.sequence || "");
    const [actionUrl, setActionUrl] = useState(bannerToEdit?.actionUrl || "");
    const [images, setImages] = useState(bannerToEdit?.imageUrl ? [bannerToEdit.imageUrl] : []);

    const handleUpdateOrPublish = async () => {
        const DATA = {
          
             sequence,
            imageUrl: images[0],
            actionUrl,
        };

        try {
            if (bannerToEdit) {
                const response = await axiosInstance.post(`${EDIT_BANNER_API}/${bannerToEdit._id}`, DATA); // Assuming you use PUT for update
                if (response.data?.error) {
                    showToast(response.data?.message || SOMETHING_WENT_WRONG);
                } else {
                    showToast("Banner updated successfully!", TOAST_SUCCESS);
                    nav("/banner/all");
                }
            } else {
                const response = await axiosInstance.post(ADD_BANNER_API, DATA);
                if (response.data?.error) {
                    showToast(response.data?.message || SOMETHING_WENT_WRONG);
                } else {
                    showToast("Banner added successfully!", TOAST_SUCCESS);
                    nav("/banner/all");
                }
            }
        } catch (error) {
            console.error("There was an error:", error);
        }
    };

    return (
        <Card
            className={cn(styles.card, className)}
            title="Name & description"
            classTitle="title-green"
            head={
                <Link
                    className={cn("button-stroke button-small", styles.button)}
                    to="/products/dashboard"
                >
                    <Icon name="arrow-left" size="24" />
                    <span>Back</span>
                </Link>
            }
        >
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateOrPublish();
                }}
                className={styles.description}
            >
               
				<TextInput
					className={styles.field}
					label="Sequence"
					name="sequence"
					type="number"
					value={sequence}
					onChange={(e) => setSequence(Number(e.target.value))}
					required
				/>

				<TextInput
					className={styles.field}
					label="ActionUrl"
					name="actionUrl"
					type="text"
					value={actionUrl}
					onChange={(e) => setActionUrl(e.target.value)}
					required
				/>

				<GenericImageUpload
					className={styles.card}
					maxImages={1}
					images={images}
					onImageUpload={(image) => {
						console.log({ image });
						setImages([...images, image]);
					}}
					onImageRemove={(image) => {
						console.log({ image });
						const removedArr = images.filter((img) => img !== image);
						console.log({ removedArr });
						setImages(removedArr);
					}}
					setSortedImages={(images) => {
						setImages(images);
					}}
				>
					<div className={styles.imageContainer}>
						{images.length > 0 && (
							<img
								src={images[0]} // Assuming the URL of the uploaded image is in images[0]
								alt="Uploaded Image"
								style={{
									maxWidth: "1000px",
									maxHeight: "350px",
									width: "100%",
									height: "auto",
								}}
							/>
						)}
					</div>
				</GenericImageUpload>


                <button
                    type="submit"
                    className={cn("button", styles.button)}
                    style={{ position: "absolute", bottom: "20px", right: "20px" }}
                >
                    {bannerToEdit ? "Update" : "Publish"}
                </button>
            </form>
        </Card>
    );
};

export default BannerAdd;
