import React from 'react';

const Loader = () => {
	return (
		<div className='flex justify-center items-center  '>
			<span class='loader'></span>
		</div>
	);
};

export default Loader;
