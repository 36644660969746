import React, { useState } from "react";
import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";

import styles from "./Panel.module.sass";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import axiosInstance from "../../utils/axios";
import { BAN_USER } from "../../utils/api";
import {
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
	showToast,
} from "../../utils/constants";


const BannerAdd = ({ className }) => {
	const nav = useNavigate();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [password, setPassword] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");
	const [isSubmitting, setIsSubmitting] = useState(false);
	

	const handlePublish = async () => {
		if (isSubmitting) return; // Prevent multiple submissions

		const DATA = {
			firstName,
			lastName,
			password,
			mobileNumber,
		};

		try {
			setIsSubmitting(true); // Set the submission status to true

			const { data } = await axiosInstance.post(BAN_USER, DATA);
			if (data?.error)
				return showToast(data?.message ? data?.message : SOMETHING_WENT_WRONG);
			console.log({ data });
			showToast(data?.message, TOAST_SUCCESS);
			return nav("/banner/all");
		} catch (error) {
			console.error("There was an error publishing the data:", error);
		} finally {
			setIsSubmitting(false); // Reset the submission status
		}
	};
	return (
		<Card
			className={cn(styles.card, className)}
			title="Name & description"
			classTitle="title-green"
			head={
				<Link
					className={cn("button-stroke button-small", styles.button)}
					to="/products/dashboard"
				>
					<Icon name="arrow-left" size="24" />
					<span>Back</span>
				</Link>
			}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handlePublish();
				}}
				className={styles.description}
			>
				<TextInput
					className={styles.field}
					label="First Name"
					name="title"
					type="text"
					tooltip="Maximum 100 characters. No HTML or emoji allowed"
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					required
				/>
				<TextInput
					className={styles.field}
					label="Last Name"
					name="description"
					type="text"
					value={lastName}
					onChange={(e) => setLastName(e.target.value)}
					required
				/>
				<TextInput
					className={styles.field}
					label="Password"
					name="sequence"
					type="number"
					value={password}
					onChange={(e) => setPassword(Number(e.target.value))}
					required
				/>

				<TextInput
					className={styles.field}
					label="Mobile Number"
					name="actionUrl"
					type="text"
					value={mobileNumber}
					onChange={(e) => setMobileNumber(Number(e.target.value))}
					required
				/>

				

				<button
					type="submit"
					className={cn("button", styles.button)}
					style={{ position: "absolute", bottom: "20px", right: "20px" }}
				>
					Publish
				</button>
			</form>
		</Card>
	);
};

export default BannerAdd;
