import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { ALL_REVIEWS } from "../../utils/api";
import { Link } from "react-router-dom";
import { limitString } from "../../utils/constants";

const AllReviews = () => {
	const [reviews, setReviews] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const fetchAllReviews = async () => {
			try {
				const response = await axiosInstance.get(ALL_REVIEWS);

				if (
					response.status === 200 &&
					Array.isArray(response?.data?.payload?.docs)
				) {
					setReviews(response?.data?.payload?.docs);
				} else {
					setError("Error fetching reviews: " + response?.statusText);
				}
			} catch (error) {
				setError("Error fetching reviews: " + error?.message);
			} finally {
				setLoading(false);
			}
		};

		fetchAllReviews();
	}, []);

	const renderStars = (count) => {
		const filledStar = "★";
		const emptyStar = "☆";
		return (
			<>
				{Array.from({ length: 5 }).map((_, index) => (
					<span key={index} className="text-yellow-500 text-3xl">
						{index < count ? filledStar : emptyStar}
					</span>
				))}
			</>
		);
	};

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {error}</p>;
	// return <div>{JSON.stringify(reviews)}</div>;
	return (
		<div className="max-w-full mx-auto p-6">
			<div className="overflow-x-auto">
				<table className="min-w-full bg-white shadow-md rounded-lg">
					<thead className="bg-pink-600 text-white">
						<tr>
							<th className="text-center py-3 px-4 uppercase font-semibold text-lg">
								User
							</th>
							<th className="text-center py-3 px-4 uppercase font-semibold text-lg">
								Product
							</th>
							<th className="text-center py-3 px-4 uppercase font-semibold text-lg">
								Reviews Stars
							</th>
							<th className="text-center py-3 px-4 uppercase font-semibold text-lg">
								Status
							</th>
							<th className="text-center py-3 px-4 uppercase font-semibold text-lg">
								Created At
							</th>
						</tr>
					</thead>
					<tbody className="text-gray-700">
						{reviews.map((review, index) => (
							<tr
								key={review?._id}
								className={index % 2 === 0 ? "bg-gray-100" : ""}
							>
								<td className="text-center py-3 px-4">
									<Link
										className="hover:text-pink-600"
										to={`/reviewdetail/${review?._id}`}
									>
										{review?.user?.name ? review?.user?.name : "N/A"}
									</Link>
								</td>

								<td className="text-center py-3 px-4">
									{limitString(review?.product?.name ? review?.product?.name : "N/A",50)}
									<br />
									{review?.product?.AIN ? review?.product?.AIN : "N/A"}
								</td>
								<td className="text-center py-3 px-4">
									{renderStars(review?.count)}
								</td>
								<td className="text-center py-3 px-4">
									<span
										className={
											review?.status === "Approved"
												? "text-green-600"
												: review?.status === "Rejected"
												? "text-red-600"
												: review?.status === "Waiting Approval"
												? "text-yellow-500"
												: ""
										}
									>
										{review?.status}
									</span>
								</td>

								<td className="text-center py-3 px-4">
									{new Date(review?.createdAt).toLocaleString()}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default AllReviews;
