import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import TextInput from "../../components/TextInput";
import { Ban, PlusSquare, Trash2 } from "lucide-react";
import styles from "./Panel.module.sass";
import axiosInstance from "../../utils/axios";
import {
	ADD_SUB_CATEGORY,
	ALL_CATEGORY_API,
	EDIT_SUB_CATEGORY,
	GET_SUB_CATEGORY,
} from "../../utils/api";
import { showToast, TOAST_ERROR, TOAST_SUCCESS } from "../../utils/constants";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
const SubCategoryAdd = ({ edit }) => {
	// General variables start
	const nav = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const subCategoryId = queryParams.get("subCategoryId");
	// General variables End

	// States start
	const [allCategory, setAllCategory] = useState([]);
	const [name, setName] = useState("");
	const [categoryId, setCategoryId] = useState("");
	const [specifications, setSpecifications] = useState([
		{ key: "", value: ["", ""] },
	]);
	// States end

	// Fetcher start
	async function getAllCategories() {
		try {
			const { data } = await axiosInstance.get(ALL_CATEGORY_API);
			if (data?.error) {
				showToast("No category found, add some category first", TOAST_ERROR);
				return;
			}
			console.clear();
			console.log(data?.payload);
			setAllCategory(data?.payload);
		} catch (error) {
			console.log(error);
			showToast("Something went wrong, please refresh the page", TOAST_ERROR);
		}
	}
	async function getCurrentSubCategory() {
		try {
			const { data } = await axiosInstance.get(
				`${GET_SUB_CATEGORY}/${subCategoryId}`
			);
			if (data?.error) throw data?.message;
			const subCategory = data?.payload;
			setName(subCategory?.name);
			setCategoryId(subCategory?.categoryId);
			setSpecifications(subCategory?.specifications);
			console.log(data?.payload);
		} catch (error) {
			console.log(error);
			showToast(
				"Something went wrong, please recheck Sub Category ID",
				TOAST_ERROR
			);
			nav("/category/subcategory/add");
		}
	}
	useEffect(() => {
		getAllCategories();
		if (edit && subCategoryId?.length) getCurrentSubCategory();
		else nav("/category/subcategory/add");
	}, []);
	// Fetcher End

	// Common func start
	const addValue = (index) => {
		const updatedSpecifications = [...specifications];
		updatedSpecifications[index].value.push("");
		setSpecifications(updatedSpecifications);
	};
	const deleteValue = (specIndex, valueIndex) => {
		const updatedSpecifications = [...specifications];
		const value = updatedSpecifications[specIndex].value;

		if (value.length > 1) {
			value.splice(valueIndex, 1);
			setSpecifications(updatedSpecifications);
		}
	};
	const handleValueChange = (specIndex, valueIndex, value) => {
		const updatedSpecifications = [...specifications];
		updatedSpecifications[specIndex].value[valueIndex] = value;
		setSpecifications(updatedSpecifications);
	};
	const addSpecification = () => {
		setSpecifications([...specifications, { key: "", value: ["", ""] }]);
	};
	const deleteSpecification = (index) => {
		if (specifications.length > 1) {
			const newSpecifications = specifications.filter((_, i) => i !== index);
			setSpecifications(newSpecifications);
		}
	};
	const handleKeyChange = (index, key) => {
		const updatedSpecifications = [...specifications];
		updatedSpecifications[index].key = key;
		setSpecifications(updatedSpecifications);
	};
	async function handleSubmit() {
		const requestBody = { id: subCategoryId, categoryId, name, specifications };
		const endPoint = edit ? EDIT_SUB_CATEGORY : ADD_SUB_CATEGORY;
		console.log(requestBody, endPoint);
		try {
			const { data } = await axiosInstance.post(endPoint, requestBody);
			if (data?.error) throw data?.message;
			showToast(
				`${name} has been ${edit ? "edited" : "added"} successfully`,
				TOAST_SUCCESS
			);
			console.log("Success", data);
		} catch (error) {
			console.log(error);
			showToast("Please try again", TOAST_ERROR);
		}
	}
	// Common func end

	return (
		<>
			<ToastContainer />
			<Card
				className={styles.card}
				title="Name & Specification"
				classTitle="title-green"
			>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
					className="w-full flex flex-col gap-y-5"
				>
					<span>Category</span>
					<select
						required
						value={categoryId}
						onChange={(e) => {
							setCategoryId(e.target.value);
							console.log(categoryId);
						}}
						className="border h-10 rounded-lg ring-1 pl-3 ring-pink-600 bg-pink-600 text-white"
					>
						<option value="" disabled>
							Select a category
						</option>
						{allCategory.map((e, index) => (
							<option key={index} value={e._id}>
								{e?.name} ({e?._id})
							</option>
						))}
					</select>

					<TextInput
						className={styles.field}
						label="Sub Category name"
						name="title"
						type="text"
						value={name}
						onChange={(e) => setName(e.target.value)}
						required
					/>
					<div className="flex flex-col mb-4 w-full gap-y-5">
						<label className="text-lg font-semibold">Specifications</label>
						{specifications.map((spec, index) => (
							<div
								key={index}
								className="flex flex-col gap-x-6 gap-y-3 lg:flex-row mb-2"
							>
								<TextInput
									placeholder="Spec name"
									value={spec.key}
									onChange={(e) => handleKeyChange(index, e.target.value)}
									required
								/>
								<div className="grid grid-cols-3 xl:grid-cols-4 gap-2">
									{spec.value.map((value, valueIndex) => (
										<div key={valueIndex} className="flex gap-x-2 items-center">
											<TextInput
												placeholder={`Value ${valueIndex + 1}`}
												value={value}
												onChange={(e) =>
													handleValueChange(index, valueIndex, e.target.value)
												}
												required
											/>
											{valueIndex > 0 && (
												<button
													type="button"
													onClick={() => deleteValue(index, valueIndex)}
													className="bg-blue-500 min-w-[3rem] h-12 flex justify-center items-center rounded-full hover:bg-red-600 transition-all"
												>
													<Ban color="white" className="min-w-[1.5rem]" />
												</button>
											)}
										</div>
									))}
									<button
										type="button"
										onClick={() => addValue(index)}
										className="bg-pink-600 w-16 h-12 flex items-center justify-center py-1.5 rounded-2xl hover:bg-pink-500 transition-all"
									>
										<PlusSquare color="white" />
									</button>
								</div>
								{specifications.length > 1 && (
									<div className="flex items-center">
										<button
											type="button"
											onClick={() => deleteSpecification(index)}
											className="bg-red-600 w-12 h-12 flex justify-center items-center rounded-full hover:bg-red-500 transition-all"
										>
											<Trash2 color="white" />
										</button>
									</div>
								)}
							</div>
						))}
						<button
							type="button"
							onClick={addSpecification}
							className="bg-pink-600 w-full flex justify-center py-1.5 rounded-lg hover:bg-pink-500 transition-all"
						>
							<PlusSquare color="white" />
						</button>
					</div>
					<button
						type="submit"
						className="bg-blue-500 rounded-lg w-40 py-1.5 text-white hover:bg-gray-100 hover:text-zinc-700 transition-all duration-200"
					>
						{edit ? "UPDATE" : "Add"}
					</button>
				</form>
			</Card>
		</>
	);
};

export default SubCategoryAdd;
