import React, { useState } from "react";
import cn from "classnames";
import styles from "./Entry.module.sass";
import TextInput from "../../../components/TextInput";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CREATE_SELLER } from "../../../utils/api";
import axiosInstance from "../../../utils/axios";
import {
	showToast,
	SOMETHING_WENT_WRONG,
	TOAST_ERROR,
	TOAST_SUCCESS,
} from "../../../utils/constants";
import { logger } from "../../../utils/logger";

const Entry = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		password: "",
		mobileNumber: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleContinue = async () => {
		try {
			const { data } = await axiosInstance.post(CREATE_SELLER, formData);
			if (data?.error)
				return showToast(data?.message || SOMETHING_WENT_WRONG, TOAST_ERROR);
			logger.log({
				data: data,
			});
			return showToast(data?.message || SOMETHING_WENT_WRONG, TOAST_SUCCESS);
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG, TOAST_ERROR);
		}
	};

	// const handleContinue = () => {
	// 	// Make a POST request to your API with the user data using Axios
	// 	axiosInstance
	// 		.post(CREATE_SELLER, formData)
	// 		.then((response) => {
	// 			// Handle the API response data here
	// 			console.log(response.data);

	// 			// Show a toast notification with the response data
	// 			toast.success(response.data.message, {
	// 				position: "top-right",
	// 				autoClose: 5000, // Duration of the toast message in milliseconds
	// 				hideProgressBar: false,
	// 			});
	// 		})
	// 		.catch((error) => {
	// 			// Handle errors here
	// 			console.error(error);

	// 			// Show a toast notification for the error
	// 			toast.error("An error occurred. Please try again.", {
	// 				position: "top-right",
	// 				autoClose: 5000,
	// 				hideProgressBar: false,
	// 			});
	// 		});
	// };

	return (
		<div className={styles.entry}>
			<div className={styles.body}>
				<div className={styles.info}>Or continue with an email address</div>
				<TextInput
					className={styles.field}
					name="name"
					type="text"
					placeholder="Your Name"
					required
					icon="user"
					value={formData.name}
					onChange={handleChange}
				/>
				<TextInput
					className={styles.field}
					name="email"
					type="email"
					placeholder="Your email"
					required
					icon="envelope"
					value={formData.email}
					onChange={handleChange}
				/>
				<TextInput
					className={styles.field}
					name="password"
					type="password"
					placeholder="Your Password"
					required
					icon="lock"
					value={formData.password}
					onChange={handleChange}
				/>
				<TextInput
					className={styles.field}
					name="mobileNumber"
					type="text"
					placeholder="Mobile Number"
					required
					icon="lock"
					value={formData.mobileNumber}
					onChange={handleChange}
				/>
				<button
					className={cn("button", styles.button)}
					onClick={handleContinue}
				>
					Continue
				</button>
				<div className={styles.note}>
					This site is protected by reCAPTCHA and the Google Privacy Policy.
				</div>
			</div>
			{/* Add ToastContainer to display toast notifications */}
			<ToastContainer />
		</div>
	);
};

export default Entry;
