/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { DELETE_SUB_CATEGORY, GET_ALL_SUB_CATEGORY } from "../../utils/api";
import {
	SOMETHING_WENT_WRONG,
	TOAST_ERROR,
	TOAST_SUCCESS,
	showToast,
} from "../../utils/constants";
import axiosInstance from "../../utils/axios";
import Loading from "../../components/Loading";
import { Link, useNavigate } from "react-router-dom";
import { Pencil, Trash2 } from "lucide-react";

export default function SubCategory() {
	const nav = useNavigate();
	const [allSubCategory, setAllSubCategory] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const fetchAllSubCategory = async () => {
		try {
			setIsLoading(true);
			const { data } = await axiosInstance.get(GET_ALL_SUB_CATEGORY);
			if (data?.error) {
				showToast(data?.message || SOMETHING_WENT_WRONG, "error");
				return;
			}
			setAllSubCategory(data?.payload);
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG, "error");
		} finally {
			setIsLoading(false);
		}
	};
	async function handleDelete(id) {
		try {
			const { data } = await axiosInstance.delete(
				`${DELETE_SUB_CATEGORY}/${id}`
			);
			if (data?.error) throw data?.message;
			showToast("Deleted Successfully", TOAST_SUCCESS);
			fetchAllSubCategory();
		} catch (error) {
			console.log(error);
			showToast("Refresh the page and try again", TOAST_ERROR);
		}
	}
	useEffect(() => {
		const fetchData = async () => {
			await fetchAllSubCategory();
		};
		fetchData();
	}, []);

	return (
		<main className="w-full flex flex-col gap-y-2">
			<Link
				to={"/category/subcategory/add"}
				className="bg-blue-500 self-end rounded-lg mr-5 px-4 py-1.5 text-white hover:bg-gray-100 hover:text-zinc-700 transition-all duration-200"
			>
				New sub category
			</Link>
			<div className="flex flex-col gap-y-10">
				{isLoading ? (
					<Loading />
				) : (
					<section className="flex flex-col gap-y-3">
						{/* Header start */}
						<div className="w-full text-center h-12 grid grid-cols-5 text-xs sm:text-sm lg:text-lg gap-3 place-items-center rounded-t-2xl bg-pink-600 text-white">
							<span>ID</span>
							<span>Under Category</span>
							<span>Name</span>
							<span>Specifications</span>
							<span>Action</span>
						</div>
						{/* Header End */}

						{/* Rows start */}
						{allSubCategory.length === 0 ? (
							<span className="w-full text-center text-xl">
								No sub category found
							</span>
						) : (
							allSubCategory.map((e) => {
								return (
									<div
										key={e?._id}
										className="grid grid-cols-5 text-xs sm:text-sm lg:text-base gap-3 place-items-center hover:text-pink-600 hover:cursor-pointer hover:ring-1 ring-pink-600 transition-all duration-300 rounded-xl"
									>
										<div className="text-xs">{e?._id}</div>
										<div>{e?.category?.name}</div>
										<div>{e?.name}</div>
										<div className="flex flex-col text-xs md:text-sm">
											{e?.specifications?.map((ee, index) => (
												<span className="overflow-auto">
													{index + 1}. {ee?.key}
												</span>
											))}
										</div>
										<div className="flex gap-x-3">
											<span
												onClick={() =>
													nav(
														`/category/subcategory/edit?subCategoryId=${e?._id}`
													)
												}
												className="hover:bg-green-500 p-2 rounded-2xl hover:text-white transition-all"
											>
												<Pencil />
											</span>
											<span
												onClick={() => {
													const res = confirm(
														`Do you want to delete "${e?.name}"`
													);
													if (res) {
														handleDelete(e?._id);
													}
												}}
												className="hover:bg-red-600 p-2 rounded-2xl hover:text-white transition-all"
											>
												<Trash2 />
											</span>
										</div>
									</div>
								);
							})
						)}
						{/* Rows End  */}
					</section>
				)}
			</div>
		</main>
	);
}
