/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import cn from "classnames";
import Card from "../../../components/Card";
import { SortableContainer, SortableElement } from "react-sortable-hoc";


const ImagesAndCTA = ({ className }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const maxImages = 5;

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    if (selectedImages.length + files.length <= maxImages) {
      const newImages = [];

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const uniqueId = Date.now(); // Generate a unique identifier
          newImages.push({ id: uniqueId, imageUrl: event.target.result });
          if (newImages.length === files.length) {
            setSelectedImages([...selectedImages, ...newImages]);
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      alert(`You can upload a maximum of ${maxImages} images.`);
    }

    e.target.value = null;
  };

  const handleRemoveImage = (id) => {
    const updatedImages = selectedImages.filter((image) => image.id !== id);
    setSelectedImages(updatedImages);
  };

  const SortableImage = SortableElement(({ image, index }) => (
    <div key={image.id} className="items-center space-x-4">
      <img
        className={index === 0 ? "w-80 h-80" : "w-44 h-56 rounded-xl"} // Increase size for the first image
        src={image.imageUrl}
        alt={`Image ${index + 1}`}
      />
      <button
        className="text-red-500 hover:text-red-700"
        onClick={() => handleRemoveImage(image.id)}
      >
        Remove
      </button>
    </div>
  ));
  const SortableImageList = SortableContainer(({ images }) => (
    <div className="flex space-x-4">
      {images.map((image, index) => (
        <SortableImage key={image.id} index={index} image={image} />
      ))}
    </div>
  ));

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedImages = [...selectedImages];
    const movedImage = updatedImages[oldIndex];
    updatedImages.splice(oldIndex, 1);
    updatedImages.splice(newIndex, 0, movedImage);
    setSelectedImages(updatedImages);
  };

  return (
    <Card
      className={cn("p-4", className)}
      title="Images & CTA"
    >
      <div className="mb-4">
        <input
          className="border p-2 rounded"
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
        />
      </div>

      <SortableImageList
        images={selectedImages}
        onSortEnd={onSortEnd}
        axis="xy"
      />

      <div className="mt-4">
        Remaining images to upload: {maxImages - selectedImages.length}
      </div>
    </Card>
  );
};

export default ImagesAndCTA;