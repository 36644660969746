/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { useParams, useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { DELETE_VARIANT } from "../../utils/api";
import DeleteModal from "../../components/DeleteModal";

import {
	CURRENCY,
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
	showToast,
} from "../../utils/constants";

const ProductDetail = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [product, setProduct] = useState(null);
	// Initialize the approvedVariants state from localStorage
	const [approvedVariants, setApprovedVariants] = useState(
		JSON.parse(localStorage.getItem("approvedVariants")) || {}
	);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [variantToDelete, setVariantToDelete] = useState(null);

	const openModal = (variantId) => {
		setVariantToDelete(variantId);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setVariantToDelete(null);
	};

	const confirmDelete = () => {
		if (variantToDelete) {
			deleteVariant(variantToDelete);
			closeModal();
		}
	};

	//to delete variant inside product detail page

	const deleteVariant = (variantId) => {
		// const endpoint = `v1/employee/variant/delete/${variantId}`;

		axiosInstance
			.delete(`${DELETE_VARIANT}/${variantId}`)

			.then(() => {
				showToast(TOAST_SUCCESS, "Variant deleted successfully");
				// Filter out the deleted variant
				setProduct((currentProduct) => ({
					...currentProduct,
					variants: currentProduct?.variants?.variantId?.filter(
						(variant) => variant?._id !== variantId
					),
				}));
			})
			.catch((error) => {
				console.error("Error deleting the variant:", error);
				showToast(SOMETHING_WENT_WRONG, "Error deleting the variant");
			});
	};

	const approveVariant = (variantId, index) => {
		const endpoint = `/v1/employee/variant/change-status/${variantId}`;
		const data = { status: true };

		axiosInstance
			.post(endpoint, data)
			.then(() => {
				showToast(TOAST_SUCCESS, "Variant approved successfully");
				// Update the approvedVariants state and persist it to localStorage
				const newApprovedVariants = { ...approvedVariants, [variantId]: true };
				setApprovedVariants(newApprovedVariants);
				localStorage.setItem(
					"approvedVariants",
					JSON.stringify(newApprovedVariants)
				);
				// Redirect to the approval page
				navigate("/productapprove");
			})
			.catch((error) => {
				console.error("Error approving the variant:", error);
				showToast(SOMETHING_WENT_WRONG, "error");
			});
	};

	useEffect(() => {
		axiosInstance
			.get(`/v1/employee/product/detail/${id}`)
			.then((response) => {
				setProduct(response?.data?.payload);
				showToast(TOAST_SUCCESS, "Product details fetched successfully");
			})
			.catch((error) => {
				console.error("Error fetching product details:", error);
				showToast(SOMETHING_WENT_WRONG, "Error fetching product details");
			});
	}, [id]);

	if (!product) {
		return <div>Loading....</div>;
	}

	// function getVariant(index) {
	// 	console.clear();
	// 	console.log("Index is ", index);
	// 	console.log(product.variants[index]._id);
	// 	approveVariant(product.variants[index]._id, index);
	// }
	function handleApprove(variantId, index) {
		if (!approvedVariants[variantId]) {
			approveVariant(variantId, index);
		}
	}
	return (
		<div className="bg-white shadow-xl rounded-2xl p-8 w-full space-y-8">
			{/* Render Variant Details */}
			{product?.variants?.map(({ variantId: variant }, index) => (
				<div
					key={index}
					className="grid grid-cols-2 gap-4 bg-gray-200 p-4 rounded-md shadow-md hover:shadow-lg transition-shadow duration-300"
				>
					{/* Thumbnail Image */}
					<div>
						<div className="flex justify-between space-x-2"></div>

						<h3 className="font-bold text-center text-lg p-5">
							Thumbnail Image
						</h3>

						{variant?.thumbnailImage && (
							<div className="col-span-1 flex items-center justify-center">
								<img
									src={variant?.thumbnailImage}
									alt={`Thumbnail Image for Variant ${index + 1}`}
									className="w-50 h-50  rounded-md shadow border border-gray-300"
								/>
							</div>
						)}

						{variant?.images?.length > 0 && (
							<div className="mb-2">
								<h3 className="font-bold text-center text-lg p-5">Images</h3>
								<div className="flex flex-wrap">
									{variant?.images?.map((image, imgIndex) => (
										<div key={imgIndex} className="w-1/3 sm:w-1/3 p-2">
											<img
												src={image}
												alt={`Variant ${index + 1} Image ${imgIndex + 1}`}
												className="w-25 h-25 rounded-md shadow border border-gray-300 transform hover:scale-105 transition-transform duration-300"
											/>
										</div>
									))}
								</div>
							</div>
						)}
					</div>

					{/* Variant Details */}
					<div className="col-span-1 p-10 bg-white border rounded-lg ">
						<h3 className="font-bold text-center text-3xl text-pink-700 p-5">
							Product Info
						</h3>
						<table className="table-auto w-full border-collapse border border-gray-400 mt-6">
							<tbody>
								<tr>
									<td className=" text-xl p-2 border border-gray-400">Name:</td>

									<td className="p-2 border  border-gray-400">
										{product?.name}
									</td>
								</tr>

								<tr>
									<td className=" text-xl p-2 border border-gray-400">
										Description:
									</td>
									<td className="p-2 border border-gray-400">
										{product?.description}
									</td>
								</tr>
								<tr>
									<td className=" text-xl p-2 border border-gray-400">
										Brand:
									</td>
									<td className="p-2 border border-gray-400">
										{product?.brand}
									</td>
								</tr>
								<tr>
									<td className=" text-xl p-2 border border-gray-400">
										Available On:
									</td>
									<td className="p-2 border border-gray-400">
										{new Date(product?.availableOn).toLocaleDateString()}
									</td>
								</tr>
							</tbody>
						</table>

						<div className="bg-gray-100 p-4 rounded-md">
							<h3 className="font-bold text-center text-3xl text-pink-700 p-5">
								Features
							</h3>
							<div className="grid grid-cols-2  gap-2">
								{product?.features?.map((feature, index) => (
									<div key={index}>{feature}</div>
								))}
							</div>
						</div>

						<h3 className="font-bold text-center text-3xl text-pink-700 p-5">
							Variant Details
						</h3>

						<table className="table-auto w-full border-collapse border border-gray-400 mt-6">
							<tbody>
								<tr>
									<td className=" text-xl p-2 border border-gray-400">
										Market Price:
									</td>
									<td className="p-2 border border-gray-400">
										{CURRENCY} {variant?.price?.marketPrice}
									</td>
								</tr>
								<tr>
									<td className=" text-xl p-2 border border-gray-400">
										MakeItLess Price:
									</td>
									<td className="p-2 border border-gray-400">
										{CURRENCY} {variant?.price?.discountPrice}
									</td>
								</tr>
								<tr>
									<td className=" text-xl p-2 border border-gray-400">
										Selling Price:
									</td>
									<td className="p-2 border border-gray-400">
										{CURRENCY} {variant?.price?.sellingPrice}
									</td>
								</tr>

								<tr>
									<td className=" text-xl p-2 border border-gray-400">
										Stock:
									</td>
									<td className="p-2 border border-gray-400">
										{variant.stock}
									</td>
								</tr>
							</tbody>
						</table>
						<div className="flex justify-between">
							<button
								className={`mt-4 ${
									approvedVariants[variant._id]
										? "bg-slate-500 cursor-not-allowed"
										: "bg-green-600 hover:bg-green-700"
								} text-white py-2 px-4 rounded`}
								onClick={() => handleApprove(variant._id, index)}
								disabled={approvedVariants[variant._id]}
							>
								{approvedVariants[variant._id] ? "Approved" : "Approve"}
							</button>
							<button
								className="mt-4 bg-red-600 hover:bg-red-700 text-white py-2 px-6 rounded"
								onClick={() => openModal(variant._id)}
							>
								Delete
							</button>
						</div>
					</div>
				</div>
			))}
			{isModalOpen && (
				<DeleteModal
					isOpen={isModalOpen}
					onClose={closeModal}
					onConfirm={confirmDelete}
					content={`Are you sure you want to delete this variant?`}
				/>
			)}
		</div>
	);
};

export default ProductDetail;
