import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import styles from "./Panel.module.sass";
import Card from "../../components/Card";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import axiosInstance from "../../utils/axios";
import { ADD_CATEGORY_API, DETAIL_CATEGORY_API } from "../../utils/api";
import {
	SOMETHING_WENT_WRONG,
	TOAST_SUCCESS,
	showToast,
} from "../../utils/constants";
import { EDIT_CATEGORY_API } from "../../utils/api";
import GenericImageUpload from "../../screens/NewProduct/GenericImageUpload";
import { logger } from "../../utils/logger";

const CategoryAdd = ({ className }) => {
	const nav = useNavigate();
	const location = useLocation();
	const categoryId = location.state?.categoryId;

	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [sequence, setSequence] = useState("");
	const [images, setImages] = useState([]);

	const _getDetail = async () => {
		try {
			const { data } = await axiosInstance.get(
				DETAIL_CATEGORY_API + "/" + categoryId
			);
			if (data?.error) return showToast(data?.message || SOMETHING_WENT_WRONG);
			logger.log({
				data: data,
			});
			setName(data?.payload?.name);
			setDescription(data?.payload?.description);
			setSequence(data?.payload?.sequence);
			setImages([data?.payload?.image]);
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG);
		}
	};
	useEffect(() => {
		if (categoryId) _getDetail();
	}, [categoryId]);

	const handleUpdateOrPublish = async () => {
		const DATA = {
			name,
			description,
			sequence,
			image: images[0],
		};

		logger.log({
			DATA,
		});

		try {
			const response = await axiosInstance.post(
				categoryId ? `${EDIT_CATEGORY_API}/${categoryId}` : ADD_CATEGORY_API,
				DATA
			);
			if (response.data?.error) {
				showToast(response.data?.message || SOMETHING_WENT_WRONG);
			} else {
				showToast("Category added successfully!", TOAST_SUCCESS);
				nav("/category/all");
			}
		} catch (error) {
			console.error("There was an error:", error);
		}
	};

	return (
		<Card
			className={cn(styles.card, className)}
			title="Name & description"
			classTitle="title-green"
			head={
				<>
					<Link to={'/category/subcategory/add'} className="bg-blue-500 rounded-lg mr-5 px-4 py-1.5 text-white hover:bg-gray-100 hover:text-zinc-700 transition-all duration-200">
						New sub category
					</Link>
					<Link
						className={cn("button-stroke button-small", styles.button)}
						to="/products/dashboard"
					>
						<Icon name="arrow-left" size="24" />
						<span>Back</span>
					</Link>
				</>
			}
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleUpdateOrPublish();
				}}
				className={styles.description}
			>
				<TextInput
					className={styles.field}
					label="Category name"
					name="title"
					type="text"
					tooltip="Maximum 100 characters. No HTML or emoji allowed"
					value={name}
					onChange={(e) => setName(e.target.value)}
					required
				/>
				<TextInput
					className={styles.field}
					label="Description"
					name="description"
					type="text"
					value={description}
					onChange={(e) => setDescription(e.target.value)}
					required
				/>
				<TextInput
					className={styles.field}
					label="Sequence"
					name="sequence"
					type="number"
					value={sequence}
					onChange={(e) => setSequence(Number(e.target.value))}
					required
				/>

				<GenericImageUpload
					className={styles.card}
					maxImages={1}
					images={images}
					onImageUpload={(image) => {
						console.log({ image });
						setImages([...images, image]);
					}}
					onImageRemove={(image) => {
						console.log({ image });
						const removedArr = images.filter((img) => img !== image);
						console.log({ removedArr });
						setImages(removedArr);
					}}
					setSortedImages={(images) => {
						setImages(images);
					}}
				>
					<div className={styles.imageContainer}>
						{images.length > 0 && (
							<img
								src={images[0]} // Assuming the URL of the uploaded image is in images[0]
								alt="Uploaded Image"
								style={{
									maxWidth: "1000px",
									maxHeight: "350px",
									width: "100%",
									height: "auto",
								}}
							/>
						)}
					</div>
				</GenericImageUpload>
				<button
					type="submit"
					className={cn("button", styles.button)}
					style={{ position: "absolute", bottom: "20px", right: "20px" }}
				>
					{categoryId ? "Update" : "Publish"}
				</button>
			</form>
		</Card>
	);
};

export default CategoryAdd;
