import React, { useEffect, useState } from "react";
import styles from "./CustomerList.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import CSS for styling

import Table from "./Table";
import Details from "./Details";
import {
	SELLER_LIST,
} from "../../utils/api";
import {
  SOMETHING_WENT_WRONG,
} from "../../utils/constants";
import axiosInstance from "../../utils/axios";
import { logger } from "../../utils/logger";

const navigation = ["Active", "New"];

const ApproveList = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);
  const [seller, setSeller] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const showToast = (message, type) => {
    toast(message, {
      type: type === "success" ? toast.TYPE.SUCCESS : toast.TYPE.ERROR,
    });
  };

  return (
    <>
      <Card
        className={styles.card}
        title="Approval"
        classTitle={cn("title-purple", styles.title)}
        classCardHead={cn(styles.head, { [styles.hidden]: visible })}
        head={
          <>
            <Form
              className={styles.form}
              value={search}
              setValue={setSearch}
             
              placeholder="Search by name or email"
              type="text"
              name="search"
              icon="search"
            />
          </>
        }
      >
        <Table
          data={seller}
         
        />
        <Details className={styles.details} onClose={() => setVisible(false)} />
      </Card>

      {/* Toast container for displaying messages */}
      <ToastContainer />
    </>
  );
};

export default ApproveList;
