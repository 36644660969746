import { Routes, Route } from "react-router-dom";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./screens/Home";
import ProductsDashboard from "./screens/ProductsDashboard";
import NewProduct from "./screens/NewProduct";
import Drafts from "./screens/Drafts";
import Released from "./screens/Released Admin";
import Comments from "./screens/Comments";
import Scheduled from "./screens/Scheduled";
import Customers from "./screens/Customers";

import Promote from "./screens/Promote";
import Notification from "./screens/Notification";
import Settings from "./screens/Settings";
import UpgradeToPro from "./screens/UpgradeToPro";
import MessageCenter from "./screens/MessageCenter";
import ExploreCreators from "./screens/ExploreCreators";
import AffiliateCenter from "./screens/AffiliateCenter";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Earning from "./screens/Earning";

import Payouts from "./screens/Payouts";
import Statements from "./screens/Statements";
import Shop from "./screens/Shop";
import PageList from "./screens/PageList";
import AdminSignin from "./screens/AdminSignin";
import AdminSignup from "./screens/AdminSingup";
import { Provider, useDispatch } from "react-redux";
import store from "./store";
import { setAuthentication } from "./store/globalSlice";
import { useEffect } from "react";
import { setSession } from "./utils/jwt";
import { TOKEN } from "./utils/constants";
import { useSelector } from "react-redux";
import { logger } from "./utils/logger";
import PrivateRoutes from "./components/PrivateRoutes";
import LandinngPage from "./screens/LandingPage/Index";
import CategoryAdd from "./pages/CategoryAdd";

import CategoryList from "./pages/CategoryList";
import BannerAdd from "./pages/BannerAdd";
import BannerList from "./pages/BannerList";
import BanUser from "./pages/BanUser";
import SellerList from "./pages/SellerList";

import AddEmploee from "./pages/AddEmploee";
import UserList from "./pages/UserList";
import UserDetails from "./pages/UserDetail";
import SellerDetail from "./pages/SellerDetail";
import CategouryDetail from "./pages/CategoryDetail";
import ProductApprove from "./pages/ProductApprove";
import ProductDetail from "./pages/ProductDetail";
import AllBids from "./pages/AllBids";
import AllReviews from "./pages/AllReviews";
import ReviewDetail from "./pages/AllReviews/reviewdetail";
import AllOrders from "./pages/AllOrders";
import OrderDetail from "./pages/Orderdetails";
import LoadingComponent from "./components/LoadingComponent";
import AllQueries from "./pages/Queries";
import SubCategory from "./pages/SubCategory";
import SubCategoryAdd from "./pages/SubCategoryAdd";
import { ToastContainer } from "react-toastify";

function App() {
	const { isAuthenticated, isLoading } = useSelector((state) => state.global);
	const dispatch = useDispatch();
	const _checkAuthentication = async () => {
		const token = localStorage.getItem(TOKEN);
		logger.log({ token });
		if (token) {
			dispatch(setAuthentication(true));
			setSession(token);
		} else {
			dispatch(setAuthentication(false));
		}
	};
	useEffect(() => {
		_checkAuthentication();
	}, []);
	useEffect(() => {
		if (isLoading) return;
		// if (isAuthenticated) {
		// 	_getProfile();
		// }
	}, [isAuthenticated]);
	if (isLoading) return <LoadingComponent />;
	return (
		<>
			<ToastContainer />
			<Routes>
				<Route path="/">
					<Route element={<PrivateRoutes />}>
						<Route
							path="/dashboard"
							element={
								<Page title="Dashboard">
									<Home />
								</Page>
							}
						/>
					</Route>

					<Route path="/" element={<LandinngPage />} />
					<Route
						path="products/dashboard"
						element={
							<Page title="Products dashboard">
								<ProductsDashboard />
							</Page>
						}
					/>
					<Route
						path="category/add"
						element={
							<Page title="New Category">
								<CategoryAdd />
							</Page>
						}
					/>
					<Route
						path="category/all"
						element={
							<Page title="All Categories">
								<CategoryList />
							</Page>
						}
					/>
					<Route
						path="category/subcategory"
						element={
							<Page title="Sub Categories">
								<SubCategory />
							</Page>
						}
					/>
					<Route
						path="category/subcategory/add"
						element={
							<Page title="New Sub Category">
								<SubCategoryAdd edit={false} />
							</Page>
						}
					/>
					<Route
						path="category/subcategory/edit"
						element={
							<Page title="Edit Sub Category">
								<SubCategoryAdd edit={true} />
							</Page>
						}
					/>

					<Route
						path="banner/add"
						element={
							<Page title="New Category">
								<BannerAdd />
							</Page>
						}
					/>
					<Route
						path="banner/all"
						element={
							<Page title="All Banner">
								<BannerList />
							</Page>
						}
					/>

					{/* TODO comment below */}
					<Route
						path="products/drafts"
						element={
							<Page title="Drafts">
								<Drafts />
							</Page>
						}
					/>

					<Route
						path="products/comments"
						element={
							<Page title="Comments">
								<Comments />
							</Page>
						}
					/>
					<Route
						path="products/scheduled"
						element={
							<Page title="Scheduled">
								<Scheduled />
							</Page>
						}
					/>
					<Route
						path="customers/overview"
						element={
							<Page title="Customers">
								<Customers />
							</Page>
						}
					/>

					<Route
						path="banlist"
						element={
							<Page title="Customer List">
								<BanUser />
							</Page>
						}
					/>

					<Route
						path="sellerlist"
						element={
							<Page title="Seller List">
								<SellerList />
							</Page>
						}
					/>
					<Route
						path="sellerdetail/:id"
						element={
							<Page title="Seller Detail">
								<SellerDetail />
							</Page>
						}
					/>
					<Route
						path="productdetail/:id"
						element={
							<Page title="Variant">
								<ProductDetail />
							</Page>
						}
					/>
					<Route
						path="categorydetail/:id"
						element={
							<Page title="Category Detail">
								<CategouryDetail />
							</Page>
						}
					/>

					<Route
						path="userlist"
						element={
							<Page title="User List">
								<UserList />
							</Page>
						}
					/>
					<Route
						path="addEmpolee"
						element={
							<Page title="Add Emploee">
								<AddEmploee />
							</Page>
						}
					/>

					<Route
						path="allbids"
						element={
							<Page title="All Bids">
								<AllBids />
							</Page>
						}
					/>
					<Route
						path="allorders"
						element={
							<Page title="All Orders">
								<AllOrders />
							</Page>
						}
					/>
					<Route
						path="/order/detail/:id"
						element={
							<Page title="OrderDetail">
								<OrderDetail />
							</Page>
						}
					/>
					<Route
						path="allreviews"
						element={
							<Page title="All Reviews">
								<AllReviews />
							</Page>
						}
					/>

					<Route
						path="allqueries"
						element={
							<Page title="All Queries">
								<AllQueries />
							</Page>
						}
					/>

					<Route
						path="/reviewdetail/:reviewId"
						element={
							<Page title="All Reviews">
								<ReviewDetail />
							</Page>
						}
					/>
					<Route
						path="userlist"
						element={
							<Page title="User List">
								<UserList />
							</Page>
						}
					/>
					<Route
						path="userdetail/:id"
						element={
							<Page title="User Details">
								<UserDetails />
							</Page>
						}
					/>

					<Route
						path="shop"
						element={
							<Page wide>
								<Shop />
							</Page>
						}
					/>
					<Route
						path="income/earning"
						element={
							<Page title="Earning">
								<Earning />
							</Page>
						}
					/>
					{/* <Route
					path="income/refunds"
					element={
						<Page title="Refunds">
							<Refunds />
						</Page>
					}
				/> */}
					<Route
						path="income/payouts"
						element={
							<Page title="Payouts">
								<Payouts />
							</Page>
						}
					/>
					<Route
						path="income/statements"
						element={
							<Page title="Statements">
								<Statements />
							</Page>
						}
					/>

					<Route
						path="promote"
						element={
							<Page title="Promote">
								<Promote />
							</Page>
						}
					/>

					<Route
						path="productapprove"
						element={
							<Page title="Product List">
								<ProductApprove />
							</Page>
						}
					/>

					<Route
						path="notification"
						element={
							<Page title="Notification">
								<Notification />
							</Page>
						}
					/>
					<Route
						path="settings"
						element={
							<Page title="Settings">
								<Settings />
							</Page>
						}
					/>
					<Route
						path="upgrade-to-pro"
						element={
							<Page title="Upgrade to Pro">
								<UpgradeToPro />
							</Page>
						}
					/>
					<Route
						path="message-center"
						element={
							<Page title="Message center">
								<MessageCenter />
							</Page>
						}
					/>
					<Route
						path="explore-creators"
						element={
							<Page title="Explore creators">
								<ExploreCreators />
							</Page>
						}
					/>
					<Route
						path="affiliate-center"
						element={
							<Page title="Affiliate center">
								<AffiliateCenter />
							</Page>
						}
					/>
					<Route path="sellersign-up" element={<SignUp />} />
					<Route path="login" element={<SignIn />} />
					{/* <Route path="adminsign-up" element={<AdminSignup />} /> */}
					{/* <Route path="login" element={<AdminSignin />} /> */}
					<Route path="pagelist" element={<PageList />} />
				</Route>
			</Routes>
		</>
	);
}

function Main() {
	return (
		<Provider store={store}>
			<App />
		</Provider>
	);
}

export default Main;
