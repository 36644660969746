import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ALL_CATEGORY_API } from '../../utils/api';
import axiosInstance from '../../utils/axios';

const SellerDetail = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState(null);

  useEffect(() => {
   
    axiosInstance.get(ALL_CATEGORY_API, {
      sellerId: id   // Assuming your backend expects "sellerId" in the request body.
    })
      .then(response => {
        const sellerDetails = response.data.payload.find(s => s._id === id); 
        setSeller(sellerDetails);
      })
      .catch(error => {
        console.error('Error fetching seller details:', error);
      });
  }, [id]);

  if (!seller) {
    return <div className="flex items-center justify-center h-screen">
      <div className="animate-spin  h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
    </div>;
  }

  return (
    <div className="flex flex-row items-start mt-10 space-y-5 bg-white p-6  shadow-lg">
      <img
        src={seller.image}
        alt="Seller Profile"
        className="w-56 h-40 object-cover  border-2 border-gray-200 shadow-md"
      />
      <div className="flex  mt-10 space-y-5 bg-white p-6  shadow-lg">
      <h1 className="text-2xl font-bold text-gray-800">{seller.name}</h1>
      <p className="text-lg text-gray-600">{seller.description}</p>
      <span className="text-sm text-gray-500 font-medium">{seller.sequence}</span>
    </div>
    </div>
  );
};

export default SellerDetail;
