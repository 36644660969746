/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { GET_USER } from "../../../utils/api";
import { Link } from "react-router-dom";

const Table = () => {
	// pagination
	const [seller, setSeller] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5; // Show 5 products per page

	const lastIndex = currentPage * itemsPerPage;
	const firstIndex = lastIndex - itemsPerPage;
	const currentSeller = Array.isArray(seller)
		? seller.slice(firstIndex, lastIndex)
		: []; // Show current page's categories
	const navigate = useNavigate();
	const handleNextPage = () => {
		setCurrentPage((prev) => prev + 1);
	};

	const handlePrevPage = () => {
		setCurrentPage((prev) => prev - 1);
	};

	// Fetch data for pagination
	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			try {
				const response = await axiosInstance.get(GET_USER);
				if (isMounted) {
					console.log("API Response:", response.data.payload.docs);
					setSeller(response.data.payload.docs);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, []);

	// Update 'people' state when 'data' prop changes

	return (
		<section
			class="container px-4 mx-auto"
			style={{ width: "100%", padding: 0, paddingRight: 25 }}
		>
			<div class="flex flex-col mt-6">
				<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<table class="min-w-full overflow-hidden text-base bg-pink-600 shadow-lg rounded-lg">
							<thead>
								<tr className=" ">
									<th
										scope="col"
										class="px-6 py-3  tracking-wider text-white text-center uppercase"
									>
										Name
									</th>
									<th
										scope="col"
										class="px-6 py-3  tracking-wider text-white text-center uppercase"
									>
										Contact
									</th>
									<th
										scope="col"
										class="px-6 py-3  tracking-wider text-white text-center uppercase"
									>
										Email
									</th>
									<th
										scope="col"
										class="px-6 py-3  tracking-wider text-white text-center uppercase"
									>
										User ID
									</th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 ">
								{seller.map((data, index) => (
									<tr key={index}>
										<td class="px-6 py-4 text-sm text-center  text-gray-900 whitespace-nowrap dark:text-gray-900">
											<Link to={`/userdetail/${data._id}`}>
												<span className="cursor-pointer hover:text-pink-600">
													{data?.profile?.name}
												</span>
											</Link>
										</td>

										<td class="px-6 py-4 text-sm text-center text-gray-900 whitespace-nowrap dark:text-gray-900">
											{data.mobileNumber}
										</td>

										<td class="px-6 py-4 text-sm text-center text-gray-900 whitespace-nowrap dark:text-gray-900">
											{data.email}
										</td>

										<td class="px-6 py-4 text-sm text-center text-gray-900 whitespace-nowrap dark:text-gray-900">
											{data._id}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Table;
