/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { SELLER_LIST } from "../../../utils/api";
import { Link } from "react-router-dom";
import { DELETE_SELLER } from "../../../utils/api";
import DeleteModal from "../../../components/DeleteModal";

const Table = ({ data, onEdit, onDelete }) => {
	// pagination
	const [seller, setSeller] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 20; // Show 5 products per page
	const [people, setPeople] = useState(data);
	const lastIndex = currentPage * itemsPerPage;
	const firstIndex = lastIndex - itemsPerPage;
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedSellerId, setSelectedSellerId] = useState(null);

	const currentSeller = Array.isArray(seller)
		? seller.slice(firstIndex, lastIndex)
		: []; // Show current page's categories
	const navigate = useNavigate();
	const handleNextPage = () => {
		setCurrentPage((prev) => prev + 1);
	};

	const handlePrevPage = () => {
		setCurrentPage((prev) => prev - 1);
	};

	const openModal = (sellerId) => {
		setSelectedSellerId(sellerId);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setSelectedSellerId(null);
	};

	const confirmDelete = () => {
		if (selectedSellerId) {
			handleDelete(selectedSellerId);
			closeModal();
		}
	};

	// ------------------------------------delete seller----------------------------------------

	const handleDelete = async (sellerId) => {
		try {
			const response = await axiosInstance.delete(
				`${DELETE_SELLER}/${sellerId}`
			);
			if (response.status === 200) {
				console.log("Seller deleted successfully");
				// Optionally, refresh the list or remove the item from the UI
				setSeller(seller.filter((s) => s._id !== sellerId));
			}
		} catch (error) {
			console.error("Error deleting seller:", error);
		}
	};
	// Fetch data for pagination
	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			try {
				const response = await axiosInstance.get(SELLER_LIST);
				if (isMounted) {
					console.log("API Response:", response.data.payload.docs);
					setSeller(response.data.payload.docs);
					console.log(data);
				}
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, []);

	// Update 'people' state when 'data' prop changes
	useEffect(() => {
		setPeople(data);
	}, [data]);

	return (
		<section
			class="container px-4 mx-auto"
			style={{ width: "100%", padding: 0, paddingRight: 25 }}
		>
			<div class="flex flex-col mt-6">
				<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<table class="min-w-full overflow-hidden bg-white shadow-lg rounded-lg">
							<thead>
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-xl text-center tracking-wider text-white uppercase bg-pink-600  "
									>
										Mobile Number
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xl text-center tracking-wider text-white uppercase bg-pink-600  "
									>
										Email
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xl text-center tracking-wider text-white uppercase bg-pink-600  "
									>
										Seller ID
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xl text-center tracking-wider text-white uppercase bg-pink-600  "
									>
										Status
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xl text-center tracking-wider text-white uppercase bg-pink-600  "
									>
										Delete
									</th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 ">
								{currentSeller.map((data, index) => (
									<tr key={index}>
										<td class="px-6 py-4 text-sm text-gray-900 hover:text-pink-600 whitespace-nowrap dark:text-gray-900">
											<Link to={`/sellerdetail/${data._id}`}>
												{data.mobileNumber}
											</Link>
										</td>
										<td class="px-6 py-4 text-sm text-gray-900 hover:text-pink-600 whitespace-nowrap dark:text-gray-900">
											<Link to={`/sellerdetail/${data._id}`}>{data.email}</Link>
										</td>
										<td class="px-6 py-4 text-sm text-gray-900 hover:text-pink-600 whitespace-nowrap dark:text-gray-900">
											<Link to={`/sellerdetail/${data._id}`}>{data._id}</Link>
										</td>

										<td class="px-6 py-4 text-sm whitespace-nowrap">
											<button
												class={`text-white font-bold py-2 px-4 rounded ${
													data?.seller?.status === "Waiting Approval"
														? "bg-red-500"
														: "bg-green-500"
												}`}
											>
												{data?.seller?.status}
											</button>
										</td>
										<td>
											<button
												className="bg-pink-600 p-1 rounded-md"
												onClick={() => openModal(data._id)} // Update this line
											>
												Delete
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className="flex items-center justify-center pt-6">
				{currentPage > 1 && (
					<a
						href="#"
						onClick={handlePrevPage}
						className="mx-1 text-sm font-semibold text-gray-900"
					>
						<span className="hidden lg:block">&larr; Previous</span>
						<span className="block lg:hidden">&larr;</span>
					</a>
				)}
				{currentPage < Math.ceil(data.length / itemsPerPage) && (
					<a
						href="#"
						onClick={handleNextPage}
						className="mx-1 flex items-center  rounded-md border border-gray-400 px-3 py-1 text-gray-900 hover:scale-105"
					>
						Next &rarr;
					</a>
				)}
			</div>
			<DeleteModal
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={confirmDelete}
			/>
		</section>
	);
};

export default Table;
