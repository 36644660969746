import React, { useState, useEffect } from "react";
import axiosInstance from "../../utils/axios";
import { ALL_BIDS } from "../../utils/api";
import { limitString } from "../../utils/constants";

const AllBids = () => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllBids = async () => {
      try {
        const response = await axiosInstance.get(ALL_BIDS);

        if (
          response.status === 200 &&
          Array.isArray(response.data.payload.docs)
        ) {
          setBids(response.data.payload.docs);
        } else {
          setError("Error fetching bids: " + response.statusText);
        }
      } catch (error) {
        setError("Error fetching bids: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllBids();
  }, []);


  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="max-w-full w-full mx-auto p-6">
      <div className="overflow-x-auto">
        <table className="w-full bg-white shadow-md rounded-lg">
          <thead className="bg-pink-600 text-white">
            <tr>
              <th className="text-left py-3 px-4 uppercase font-semibold text-xl">
                Product
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-xl">
                Bid Price
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-xl">
                Status
              </th>
              <th className="text-left py-3 px-4 uppercase font-semibold text-xl">
                Created At
              </th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            {bids.map((bid, index) => (
              <tr
                key={bid._id}
                className={`${index % 2 === 0 ? "bg-gray-100" : ""}`}
              >
                <td className="text-left font-semibold py-3 px-4">
                  {limitString(bid.product ? bid?.product?.name : bid?.variant ? bid?.variant?.name : "N/A",50)}
                </td>
                <td className="text-left font-semibold py-3 px-4">${bid.bidPrice}</td>
                <td className="text-left font-semibold py-3 px-4">
                  <span
                    className={
                      bid.status === "Approved"
                        ? "text-green-600"
                        : bid.status === "Rejected"
                        ? "text-red-600"
                        : ""
                    }
                  >
                    {bid.status}
                  </span>
                </td>
                <td className="text-left py-3 px-4">
                  {new Date(bid.createdAt).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllBids;
