import React, { useState } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Help from "./Help";
import Image from "../Image";

const navigation = [
	{
		title: "Home",
		icon: "home",
		url: "/",
	},

	{
		title: "Category",
		slug: "category",
		icon: "diamond",
		add: true,
		addUrl: "/category/add",
		dropdown: [
			// {
			// 	title: "Category",
			// 	url: "/category/dashboard",
			// },
			// {
			//     title: "Drafts",
			//     url: "/products/drafts",
			//     counter: "2",
			//     colorCounter: "#FFBC99",
			// },
			{
				title: "All Categories",
				url: "/category/all",
			},
			{
				title: "Sub Categories",
				url: "/category/subcategory",
			},
			// {
			//     title: "Comments",
			//     url: "/products/comments",
			// },
			// {
			//     title: "Scheduled",
			//     url: "/products/scheduled",
			//     counter: "8",
			//     colorCounter: "#B5E4CA",
			// },
		],
	},

	{
		title: "Banner",
		slug: "category",
		icon: "pie-chart",
		add: true,
		addUrl: "/banner/add",
		dropdown: [
			// {
			// 	title: "Category",
			// 	url: "/category/dashboard",
			// },
			// {
			//     title: "Drafts",
			//     url: "/products/drafts",
			//     counter: "2",
			//     colorCounter: "#FFBC99",
			// },
			{
				title: "All Banners",
				url: "/banner/all",
			},
			// {
			//     title: "Comments",
			//     url: "/products/comments",
			// },
			// {
			//     title: "Scheduled",
			//     url: "/products/scheduled",
			//     counter: "8",
			//     colorCounter: "#B5E4CA",
			// },
		],
	},

	// {
	// 	title: "Add Emploee",
	// 	slug: "category",
	// 	icon: "pie-chart",
	// 	add: true,
	// 	addUrl: "/addEmpolee",
	// 	dropdown: [

	// 		{
	// 			title: "All Emploee",
	// 			url: "/emploeeList",
	// 		},

	// 	],
	// },
	{
		title: "Seller",
		slug: "category",
		icon: "pie-chart",
		// add: true,
		// addUrl: "/addseller",
		dropdown: [
			{
				title: "Seller List",
				url: "/sellerlist",
			},
		],
	},

	{
		title: "User",
		slug: "customers",
		icon: "profile-circle",
		dropdown: [
			{
				title: "User List",
				url: "/userlist",
			},
		],
	},

	// {
	// 	title: "Orders",
	// 	icon: "pie-chart",
	// 	url: "/promote",
	// },
	{
		title: "Product List",
		icon: "pie-chart",
		url: "/productapprove",
	},

	{
		title: "All Orders",
		icon: "pie-chart",
		url: "/allorders",
	},
	{
		title: "All Bids",
		icon: "pie-chart",
		url: "/allbids",
	},
	{
		title: "All Reviews",
		icon: "pie-chart",
		url: "/allreviews",
	},
	{
		title: "All Queries",
		icon: "pie-chart",
		url: "/allqueries",
	},

	// {
	//     title: "Income",
	//     slug: "income",
	//     icon: "pie-chart",
	//     dropdown: [
	//         {
	//             title: "Earning",
	//             url: "/income/earning",
	//         },
	//         {
	//             title: "Refunds",
	//             url: "/income/refunds",
	//         },
	//         {
	//             title: "Payouts",
	//             url: "/income/payouts",
	//         },
	//         {
	//             title: "Statements",
	//             url: "/income/statements",
	//         },
	//     ],
	// },
];

const Sidebar = ({ className, onClose }) => {
	const [visibleHelp, setVisibleHelp] = useState(false);
	const [visible, setVisible] = useState(false);

	const { pathname } = useLocation();

	return (
		<>
			<div
				className={cn(styles.sidebar, className, {
					[styles.active]: visible,
				})}
			>
				<button className={styles.close} onClick={onClose}>
					<Icon name="close" size="24" style={{ color: "red" }} />
				</button>
				<div className={`${styles.pic} h-24 w-32`} onClick={onClose}>
					<Image
						className={`${styles.pic}`}
						src="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
						srcDark="https://res.cloudinary.com/dcpte972l/image/upload/v1692191670/Holiday_Vacation_Travel_Agent_Promotion_Banner_Landscape_wvx6bq.png"
						alt="Core"
					/>
				</div>
				<div className={styles.menu}>
					{navigation.map((x, index) =>
						x.url ? (
							<NavLink
								className={cn(styles.item, {
									[styles.active]: pathname === x.url,
								})}
								to={x.url}
								key={index}
								onClick={onClose}
							>
								<Icon name={x.icon} size="24" />
								{x.title}
							</NavLink>
						) : (
							<Dropdown
								className={styles.dropdown}
								visibleSidebar={visible}
								setValue={setVisible}
								key={index}
								item={x}
								onClose={onClose}
							/>
						)
					)}
				</div>
				<button className={styles.toggle} onClick={() => setVisible(!visible)}>
					<Icon name="arrow-right" size="24" />
					<Icon name="close" size="24" />
				</button>
				<div className={styles.foot}>
					{/* <button className={styles.link} onClick={() => setVisibleHelp(true)}>
						<Icon name="help" size="24" />
						Help & getting started
						<div className={styles.counter}>8</div>
					</button> */}
					<Theme className={styles.theme} visibleSidebar={visible} />
				</div>
			</div>
			<Help
				visible={visibleHelp}
				setVisible={setVisibleHelp}
				onClose={onClose}
			/>
			<div
				className={cn(styles.overlay, { [styles.active]: visible })}
				onClick={() => setVisible(false)}
			></div>
		</>
	);
};

export default Sidebar;
