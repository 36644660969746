/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef, useState } from "react";
import cn from "classnames";
import Card from "../../../components/Card";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import axiosInstance from "../../../utils/axios";
import { IMAGE_UPLOAD_API } from "../../../utils/api";
import { SOMETHING_WENT_WRONG, showToast } from "../../../utils/constants";
import { logger } from "../../../utils/logger";
import Loader from "../../../components/Loader";
import { Upload } from "lucide-react";

const GenericImageUpload = ({
	className,
	maxImages = 1,
	images,
	onImageUpload,
	onImageRemove,
	setSortedImages,
}) => {
	const [isImageUploading, setIsImageUploading] = useState(false);
	const actualUpload = async (formData) => {
		try {
			setIsImageUploading(true);
			const { data } = await axiosInstance.post(IMAGE_UPLOAD_API, formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
			if (data?.error) return showToast(data?.message || SOMETHING_WENT_WRONG);
			logger.log({
				data: data,
			});
			onImageUpload(data?.payload?.full_url);
		} catch (error) {
			console.log({ error });
			showToast(error?.response?.message || SOMETHING_WENT_WRONG);
		} finally {
			setIsImageUploading(false);
		}
	};
	const handleImageUpload = (e) => {
		const file = e.target.files[0];
		if (!file) return;
		const formData = new FormData();
		formData.append("image", file);
		actualUpload(formData);
	};

	const SortableImage = SortableElement(({ image, index }) => (
		<div key={image} className="items-center space-x-4">
			<img
				className={index === 0 ? "w-80 h-80" : "w-44 h-56 rounded-xl"} // Increase size for the first image
				src={image}
				alt={`Image ${index + 1}`}
			/>
			<button
				className="text-red-500 hover:text-red-700"
				type="button"
				onClick={() => onImageRemove(image)}
			>
				Remove
			</button>
		</div>
	));
	const SortableImageList = SortableContainer(({ images }) => (
		<div className="flex space-x-4">
			{console.log({ images })}
			{images.map((image, index) => (
				<SortableImage key={image} index={index} image={image} />
			))}
		</div>
	));

	const onSortEnd = ({ oldIndex, newIndex }) => {
		const updatedImages = [...images];
		const movedImage = updatedImages[oldIndex];
		updatedImages.splice(oldIndex, 1);
		updatedImages.splice(newIndex, 0, movedImage);
		setSortedImages(updatedImages);
	};
	const fileInputRef = useRef();

	return (
		<div className={"mt-4"}>
			<SortableImageList images={images} onSortEnd={onSortEnd} axis="xy" />
			{isImageUploading ? (
				<Loader />
			) : (
				<>
					{images.length < maxImages ? (
						<div className="mb-4">
							<button
								type="button"
								onClick={() => fileInputRef.current.click()}
								class="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
							>
								<Upload color="white" size={22} />
								<span class="mx-10">Upload Image</span>
							</button>
							<input
								hidden
								ref={fileInputRef}
								className="border p-2 rounded"
								type="file"
								accept="image/*"
								multiple={false}
								onChange={handleImageUpload}
							/>
						</div>
					) : null}
				</>
			)}

			<div className="mt-4">
				Remaining images to upload: {maxImages - images.length}
			</div>
		</div>
	);
};

export default GenericImageUpload;
