/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import { ALL_PRODUCT } from "../../../utils/api";
import { Link } from "react-router-dom";
import { DELETE_PRODUCT } from "../../../utils/api";
import { showToast } from "../../../utils/constants";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../components/DeleteModal";

const Table = () => {
	// pagination
	const [seller, setSeller] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5; // Show 5 products per page
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [productToDelete, setProductToDelete] = useState(null);

	// Fetch data for pagination
	useEffect(() => {
		let isMounted = true;

		const fetchData = async () => {
			try {
				const response = await axiosInstance.get(ALL_PRODUCT);
				if (isMounted) {
					console.log("API Response:", response?.data?.payload?.docs);
					setSeller(response?.data?.payload?.docs);
				}
			} catch (error) {
				showToast.error("Error fetching data:", error);
			}
		};

		fetchData();

		return () => {
			isMounted = false;
		};
	}, []);

	const handleDeleteClick = (productId) => {
		setProductToDelete(productId);
		setIsDeleteModalOpen(true);
	};

	// Function to confirm deletion
	const confirmDelete = async () => {
		if (productToDelete) {
			await deleteProduct(productToDelete);
			setIsDeleteModalOpen(false);
		}
	};

	// Function to close the delete modal
	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
	};

	// Function to handle product deletion
	const deleteProduct = async (productId) => {
		try {
			const response = await axiosInstance.delete(
				`${DELETE_PRODUCT}/${productId}`
			);
			if (response.status === 200) {
				// Product deleted successfully, update the UI by removing the deleted product
				setSeller((prevSeller) =>
					prevSeller?.filter((product) => product?._id !== productId)
				);
				toast.success("Product deleted successfully", { autoClose: 2000 }); // Show success toast
			}
		} catch (error) {
			console.error("Error deleting product:", error);
			toast.error("Error deleting product"); // Show error toast
		}
	};
	return (
		<section class="px-4 mx-auto sm:w-full lg:w-4/5 xl:w-3/4">
			<ToastContainer />
			<DeleteModal
				isOpen={isDeleteModalOpen}
				onClose={closeDeleteModal}
				onConfirm={confirmDelete}
			/>
			<div class="flex flex-col mt-6">
				<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 rounded-lg">
						<table class="min-w-full  overflow-hidden bg-white shadow-lg rounded-lg">
							<thead class="">
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-lg font-bold tracking-wider text-center text-white uppercase bg-pink-600 "
									>
										Product Name
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-lg font-bold tracking-wider text-center text-white uppercase bg-pink-600 "
									>
										Brand
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-lg font-bold tracking-wider text-center text-white uppercase bg-pink-600 "
									>
										Seller Name
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-lg font-bold tracking-wider text-center text-white uppercase bg-pink-600 "
									>
										Status
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-lg font-bold tracking-wider text-center text-white uppercase bg-pink-600 "
									>
										Delete
									</th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700 ">
								{seller?.map((data, index) => (
									<tr key={index}>
										<td class="px-6 py-4 text-sm text-gray-900 hover:text-pink-600 text-left whitespace-nowrap dark:text-gray-900 overflow-hidden overflow-ellipsis max-w-[10rem]">
											<Link to={`/productdetail/${data?._id}`}>
												{data?.name}
											</Link>
										</td>
										<td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap dark:text-gray-900">
											{data?.brand || data?.manufacturer}
										</td>
										<td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap dark:text-gray-900">
											{data?.seller?.name}
										</td>
										<td class="px-6 py-4 text-sm whitespace-nowrap dark:text-gray-900">
											<span
												class={`${
													data?.isApproved ? "text-green-600" : "text-red-600"
												} font-semibold`}
											>
												{data?.isApproved ? "Approved" : "Pending.."}
											</span>
										</td>
										<td class="px-6 py-4 text-center text-sm text-gray-900  dark:text-gray-900">
											<button
												onClick={() => handleDeleteClick(data?._id)}
												class="rounded-md bg-red-600 text-white p-2"
											>
												Delete
											</button>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Table;
