/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axiosInstance from "../../../utils/axios";
import { useNavigate } from "react-router-dom";
import {
	ALL_BANNER_API,
	
} from "../../../utils/api";

const Table = ({ data, onDelete }) => {

	// pagination
	const [seller, setSeller] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 5; // Show 5 products per page
	const [people, setPeople] = useState(data);
	const lastIndex = currentPage * itemsPerPage;
	const firstIndex = lastIndex - itemsPerPage;
	const currentSeller = Array.isArray(seller) ? seller.slice(firstIndex, lastIndex) : []; // Show current page's categories
    const navigate = useNavigate();
	const handleNextPage = () => {
	  setCurrentPage((prev) => prev + 1);
	};
  
	const handlePrevPage = () => {
	  setCurrentPage((prev) => prev - 1);
	};

	// Fetch data for pagination
	useEffect(() => {
	  let isMounted = true;
  
	  const fetchData = async () => {
		try {
		  const response = await axiosInstance.get(ALL_BANNER_API);
		  if (isMounted) {
			console.log("API Response:", response.data.payload); 
			setSeller(response.data.payload);
			console.log(data);
		  }
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };
  
	  fetchData();
  
	  return () => {
		isMounted = false;
	  };
	}, []);
  
	// Update 'people' state when 'data' prop changes
	useEffect(() => {
	  setPeople(data);
	}, [data]);



	const handleEditBanner = async (bannerId) => {
		try {
			const response = await axiosInstance.post(`/v1/banner/edit/${bannerId}`);
			if (response.data && !response.data.error) {
				const bannerToEdit = response.data.payload;
				navigate("/banner/add", { state: { banner: bannerToEdit } });
			} else {
				console.error("Error fetching banner details:", response.data.message);
			}
		} catch (error) {
			console.error("There was an error fetching the banner details:", error);
		}
	};

	return (
		<section
			class="container px-4 mx-auto"
			style={{ width: "100%", padding: 0, paddingRight: 25 }}
		>
			<div class="flex flex-col mt-6">
				<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
						<table class="min-w-full overflow-hidden bg-white shadow-lg rounded-lg">
							<thead>
								<tr>
								<th scope="col" class=" bg-pink-600 text-white text-xl  ">
										Banner Image
									</th>
									<th scope="col" class=" bg-pink-600 text-white text-xl  ">
										Sequence
									</th>
									<th scope="col" class=" bg-pink-600 text-white text-xl  ">
										ActionUrl
									</th>
									<th className="bg-pink-600 text-white text-xl">Delete</th>
									<th className="bg-pink-600 text-white text-xl">Edit</th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200 dark:divide-gray-700">
								{currentSeller.map((banner, index) => (
									<tr key={index}>
										<td class="px-4 py-6 flex justify-center">
												<img
													class="object-cover w-20 h-20"
													src={banner.imageUrl}
													alt={banner.title}
												/>
											</td>
										<td class="px-6 py-5 text-sm text-gray-900 whitespace-nowrap dark:text-gray-900 ">
											{banner.sequence}
										</td>
										<td class="px-6 py-4 text-sm text-gray-900 whitespace-nowrap dark:text-gray-900">
											{banner.actionUrl}
										</td>
										
										<td class="px-6 py-4 text-sm font-medium text-center whitespace-nowrap dark:text-gray-200 justify-center">
											

										<button
													onClick={() => onDelete(banner._id)}
													class="text-gray-500 transition-colors duration-200 dark:hover:text-red-500 dark:text-gray-700 "
												>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														stroke-width="1.5"
														stroke="currentColor"
														class="w-6 h-6  "
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
														/>
													</svg>
												</button>
											</td>
											<td>
												<button 
												 onClick={() => handleEditBanner(banner._id)}
												class="text-gray-500 transition-colors duration-200 dark:hover:text-blue-500 dark:text-gray-700 ">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														fill="none"
														viewBox="0 0 24 24"
														stroke-width="1.5"
														stroke="currentColor"
														class="w-6 h-6 "
													>
														<path
															stroke-linecap="round"
															stroke-linejoin="round"
															d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
														/>
													</svg>
												</button>



										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className="flex items-center justify-center pt-6">
				{currentPage > 1 && (
					<a
						href="#"
						onClick={handlePrevPage}
						className="mx-1 text-sm font-semibold text-gray-900"
					>
						<span className="hidden lg:block">&larr; Previous</span>
						<span className="block lg:hidden">&larr;</span>
					</a>
				)}
				{currentPage < Math.ceil(data.length / itemsPerPage) && (
					<a
						href="#"
						onClick={handleNextPage}
						className="mx-1 flex items-center  rounded-md border border-gray-400 px-3 py-1 text-gray-900 hover:scale-105"
					>
						Next &rarr;
					</a>
				)}
			</div>
		</section>
	);
};

export default Table;
