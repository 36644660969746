/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import {
	Table,
	TableBody,
	TableCaption,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./Table";
import { DeleteIcon, Edit, Edit2Icon, Loader, Trash } from "lucide-react";

const GenericTable = ({ data, handleDelete, onEdit }) => {
	if (!data) return <Loader color="red" />;
	return (
		<section
			class="container rounded-lg px-4 mx-auto"
			style={{ width: "100%", padding: 0, paddingRight: 25 }}
		>
			<Table className=" rounded-lg">
				<TableHeader>
					<TableRow>
						<TableHead className="bg-pink-600 text-center text-white font-bold text-xl">
							Image
						</TableHead>

						<TableHead className="bg-pink-600 text-center text-white font-bold text-xl">
							Name
						</TableHead>
						<TableHead className="bg-pink-600 text-center text-white font-bold text-xl">
							Description
						</TableHead>
						<TableHead className="bg-pink-600 text-center text-white font-bold text-xl">
							Slug
						</TableHead>
						<TableHead className="bg-pink-600 text-center text-white font-bold text-xl">
							Sequence
						</TableHead>
						<TableHead className="bg-pink-600 text-center text-white font-bold text-xl">
							Action
						</TableHead>
					</TableRow>
				</TableHeader>
				<TableBody>
					{data?.map((ele) => (
						<TableRow>
							<TableCell>
								<img
									class="object-cover w-20 h-20 "
									src={ele.image}
									alt={ele.name}
								/>
							</TableCell>
							<TableCell className="font-medium">{ele?.name}</TableCell>
							<TableCell>{ele?.description}</TableCell>

							<TableCell className="text-left">{ele?.slug}</TableCell>
							<TableCell className="text-left">{ele?.sequence}</TableCell>
							<TableCell className="text-left ">
								<div className="flex justify-around">
									<div onClick={() => handleDelete(ele?._id)}>
										<Trash />
									</div>
									<div onClick={() => onEdit(ele?._id)}>
										<Edit />
									</div>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</section>
	);
};

export default GenericTable;
